/* Metrics */

// Column Count
$srgrid-column-count: 36 !default;

// Breakpoints (Mobile First Approach)
$srgrid-breakpoints: (
    xs: 0,      // Extra Small Devices ~ Portrait Phones
    s: 480px,   // Small Devices ~ Landscape Phones
    m: 768px,   // Medium Devices ~ Portrait Tablets
    l: 1024px,  // Large Devices ~ Landscape Tablets
    xl: 1280px  // Extra Large Devices ~ Large desktops
) !default;

// Gutter Widths
$srgrid-gutter-widths: (
    xs: 5px,
    s: 5px,
    m: 10px,
    l: 15px,
    xl: 20px
) !default;


/* BEM */

$srgrid-bem-element-delimiter: '__' !default; // Double Underscore
$srgrid-bem-modifier-delimiter: '_' !default; // Single Underscore
$srgrid-bem-long-term-delimiter: '-' !default; // Dash


/* Base */

$srgrid-parent-class: 'srgrid' !default;
$srgrid-row-element-class: 'row' !default;
$srgrid-column-element-class: 'col' !default;
$srgrid-container-element-class: 'container' !default;
$srgrid-only-infix: 'only' !default;


/* Helpers */

// Show Breakpoint
$srgrid-show-grid-class: 'srgrid-current-breakpoint' !default;


/* Features */

// Debug
$srgrid-debug-background-color: #2e3b54 !default;
$srgrid-debug-color: #fff !default;

// Container
$srgrid-container-max-width: 1200px !default;
$srgrid-container-max-widths: (
    xl: 1200px
) !default;

// Offset
$srgrid-offset-modifier-class: 'offset' !default;

// Hide
$srgrid-hide-modifier-class: 'hide' !default;

// Distribution
$srgrid-distribution-around-modifier-class: 'around' !default;
$srgrid-distribution-between-modifier-class: 'between' !default;

// Reverse
$srgrid-reverse-modifier-class: 'reverse' !default;

// Reorder
$srgrid-reorder-modifier-class: 'order' !default;

// Alignment
$srgrid-alignment-start-modifier-class: 'start' !default;
$srgrid-alignment-center-modifier-class: 'center' !default;
$srgrid-alignment-end-modifier-class: 'end' !default;
$srgrid-alignment-top-modifier-class: 'top' !default;
$srgrid-alignment-middle-modifier-class: 'middle' !default;
$srgrid-alignment-bottom-modifier-class: 'bottom' !default;
$srgrid-alignment-stretch-modifier-class: 'stretch' !default;

// Block Grid
$srgrid-block-modifier-class: 'block' !default;

// Gapless
$srgrid-gapless-modifier-class: 'gapless' !default;

// Column Fractions
$srgrid-column-fractions: (1 2, 1 3, 1 4, 2 3, 3 4) !default;
