.news-item {
    padding-top: 4rem;

    &:not(:last-child) {
        padding-bottom: 4rem;
        border-bottom: 0.1rem solid $news-divider-border;
    }

    @include breakpoint(m) {
        display: flex;
        padding-top: 4.5rem;
    }

    &__img {
        display: block;
        aspect-ratio: 16/9;
        width: 17.5rem;

        @include breakpoint(m) {
            width: 14.4rem;
        }
    }

    &__content {
        margin-top: 2.5rem;

        @include breakpoint(m) {
            margin: 0 0 0 3rem;
        }

        header {
            display: flex;
            @include font-paragraph-book-4;
        }
    }

    &__tag {
        color: $color-red-500;
    }

    &__date {
        color: rgba($color-black-400, .75);
        white-space: nowrap;

        &::before {
            content: '|';
            color: $color-black-300;
            margin: 0 1rem;
        }
    }

    &__title {
        @include transition(color);
        @include font-headline-4;
        margin: 1.5rem 0 0.8rem;
        display: inline-block;
        border-bottom: 0.2rem solid transparent;
        text-decoration: none;
        color: $color-black-500;

        @include breakpoint(m) {
            margin-top: 2rem;
        }

        &:hover {
            color: $color-red-500;
            border-color: inherit;
        }
    }

    &__copy {
        @include font-paragraph-book-3;
    }
}
