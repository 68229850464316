$spacer:
    "xxs" 1.5rem 3rem 3rem 3rem,
    "xs" 2rem 2rem 2rem 2rem,
    "s" 3rem 3rem 6rem 6rem,
    "m" 5rem 5rem 7rem 7rem,
    "l" 6rem 8rem 10rem 10rem,
    "xl" 8rem 10rem 12rem 14rem;

.spacer {
    @each $type, $bp-s, $bp-m, $bp-l, $bp-xl in $spacer {
        &-#{$type} {
            @include breakpoint-property(
                'margin-bottom',
                (
                    s: #{$bp-s},
                    m: #{$bp-m},
                    l: #{$bp-l},
                    xl: #{$bp-xl}
                )
            );
        }
    }
}

.spacer-inner__top {
    @each $type, $bp-s, $bp-m, $bp-l, $bp-xl in $spacer {
        &--#{$type} {
            @include breakpoint-property(
                'padding-top',
                (
                    s: #{$bp-s},
                    m: #{$bp-m},
                    l: #{$bp-l},
                    xl: #{$bp-xl}
                )
            );
        }
    }
}