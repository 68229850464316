@mixin srgrid-generate-gapless-base(
    $class,
    $grid-column-class
) {
    .#{$class} {
        margin-left: 0;
        margin-right: 0;

        [class*=#{$grid-column-class}] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

// sR Grid Gapless Regular Feature
@mixin srgrid-generate-gapless-feature(
    $grid-gapless-class,
    $grid-column-class
) {
    $class: $grid-gapless-class;
    @include srgrid-generate-gapless-base(
            $class,
            $grid-column-class
    );
}

// sR Grid Gapless Responsive Feature
@mixin srgrid-generate-gapless-responsive-feature(
    $grid-gapless-class,
    $grid-column-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            $class: srgrid-generate-responsive-class($grid-gapless-class, null, $breakpoint-name);
            @include srgrid-generate-gapless-base(
                    $class,
                    $grid-column-class
            );
        }
    }
}

// sR Grid Gapless Only Feature
@mixin srgrid-generate-gapless-only-feature(
    $grid-gapless-class,
    $grid-column-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            $class: srgrid-generate-only-class($grid-gapless-class, null, $breakpoint-name);
            @include srgrid-generate-gapless-base(
                    $class,
                    $grid-column-class
            );
        }
    }
}
