.story-teaser-history-item {
    &__year {
        @include font-display-1;
        @include breakpoint-property(
            'margin-bottom',
            (
                s:2rem,
                m: 3rem
            )
        );
    }

    &__image-headline-wrapper {
        position: relative;
    }

    &__image {
        width: 100%;
        height: auto;
        display: block;
    }

    &__headline-wrapper {
        background: $color-white;
        position: absolute;
        @include breakpoint-property(
            'bottom',
            (
                s: -3rem,
                m: -2rem,
                l: 0
            )
        );
        @include breakpoint-property(
            'width',
            (
                s: calc(100% - 2rem),
                m: 50%,
                l: 33%
            )
        );
        @include breakpoint-property(
            'padding',
            (
                s: 1rem 1.5rem 0 1.5rem,
                m: 1.5rem 2rem 0 2rem,
                l: 2rem 3rem 0 3rem
            )
        );
        @include breakpoint-property(
            'left',
            (
                s: 1rem,
                m: 1.5rem,
                l: 3rem
            )
        );
    }

    &__headline {
        @include font-headline-1;
    }
    
    &__content {
        position: relative;
        margin: -2rem 0 0 1.5rem;
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 450;
        background: $color-white;
        box-shadow: $teaser-box-shadow;
        @include breakpoint-property(
            'padding',
            (
                s: 1rem 1.5rem 4rem 1.5rem,
                m: 1.5rem 2rem 4rem 2rem,
                l: 2rem 3rem 4rem 3rem
            )
        );
        @include breakpoint-property(
            'margin',
            (
                s: 3rem 1rem 0 1rem,
                m: 2rem 1.5rem 0 1.5rem,
                l: 0 3rem 0 3rem
            )
        );
        @include breakpoint-property(
            'width',
            (
                s: calc(100% - 2rem),
                m: 50%,
                l: 33%
            )
        );

        

        p {
            @include font-paragraph-book-3; 
        }

    }

    &--left {
        position: relative;
        @include breakpoint-property(
            'left',
            (
                s: 0,
                m: $container-padding-m,
                l: $container-padding-l,
                xl: $container-padding-xl
            )
        );
        @include breakpoint-property(
            'width',
            (
                s: 100%,
                xl: calc(100% - #{$container-padding-xl})
            )
        );
    }

    &--right {
        position: relative;
        @include breakpoint-property(
            'right',
            (
                s: 0,
                m: $container-padding-m,
                l: $container-padding-l,
                xl: $container-padding-xl
            )
        );
        @include breakpoint-property(
            'width',
            (
                s: 100%,
                xl: calc(100% - #{$container-padding-xl})
            )
        );
        .story-teaser-history-item__year {
            @include breakpoint-property(
                'text-align',
                (
                    s: left,
                    m: right
                )
            );
        }
        .story-teaser-history-item__headline-wrapper {
            left: unset;
            @include breakpoint-property(
                'right',
                    (
                        s: 1rem,
                        m: 1.5rem,
                        l: 3rem
                    )
                );
        }
        .story-teaser-history-item__content-wrapper {
            display: flex;
            justify-content: flex-end;
        }
    }

    &--twoCols {
        @include breakpoint-property(
            'margin-top',
                (
                    s: 8rem,
                    m: 0
                )
            );
        .story-teaser-history-item__content {
            @include breakpoint-property(
                'width',
                    (
                        s: calc(100% - 2rem),
                        m: calc(100% - 3rem),
                        l: calc(100% - 6rem)
                    )
                );
        }
        .story-teaser-history-item__headline-wrapper {
            @include breakpoint-property(
                'width',
                    (
                        s: calc(100% - 2rem),
                        m: calc(100% - 3rem),
                        l: calc(100% - 6rem)
                    )
                );
            
        }
    }
}
