.people {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include breakpoint-property(
        'margin',
        (
            s: 0 0 -5rem 0,
            m: 0 -1.5rem -7rem -1.5rem,
            l: 0 -2.5rem -8rem -2.5rem,
            xl: 0 -3.75rem -10rem -3.75rem
        )
    );
}
