$spacings-values: 5, 10, 12, 15, 17, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160;
$spacings-directions: top, bottom;

.page-main {
    margin-top: $header-height-mobile;
    position: relative;
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint-property(
        'margin-top',
        (
            m: $header-height-tablet,
            xl: $header-height-desktop
        )
    );

    .grid__container {
        > .breadcrumb:first-child {
            @include breakpoint-property(
                'margin-top',
                (
                    s: $header-height-mobile + 3rem,
                    m: $header-height-tablet + 3rem,
                    l: $header-height-tablet + 6rem,
                    xl: $header-height-desktop + 6rem
                )
            );
        }
        /* no breadcrumb - first element is editor content */
        > .block:first-child {
            > .editor-content:first-child {
                @include breakpoint-property(
                    'margin-top',
                    (
                        s: $header-height-mobile + 6rem,
                        m: $header-height-tablet + 6rem,
                        l: $header-height-tablet + 10rem,
                        xl: $header-height-desktop + 12rem
                    )
                );
            }
        }
        /* no breadcrumb - first element is a headline */
        > .block:first-child {
            > .grid__row:first-child {
                > .grid__col:first-child {
                    > h1:first-child {
                        @include breakpoint-property(
                            'margin-top',
                            (
                                s: 6rem,
                                l: 10rem,
                                xl: 12rem
                            )
                        );
                    }
                }
            }
        }
    }
}



.display-inline-block {
    display: inline-block;
}
.display-block {
    display: block;
}
.overflow-hidden {
    overflow: hidden;
}
.is-hidden {
    display: none !important;
}

.spacing {
    @each $spacings-direction in $spacings-directions {
        &-#{$spacings-direction} {
            @each $spacings-value in $spacings-values {
                &-#{$spacings-value} {
                    margin-#{$spacings-direction}: #{$spacings-value}px;
                }
            }
        }
    }
}


/*
    Default transition values
*/

$transition-duration: .25s;
$transition-function: ease-out;

/*
    Mixins
 */

@mixin transition( $properties... ) {
    transition-property: $properties;
    transition-duration: $transition-duration;
}
