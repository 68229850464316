.headline-copy {
    &__lead-text {
        @include breakpoint-property(
            'margin-top',
            (
                s: 2rem,
                l: 2.5rem
            )
        );
    }
    &__content {
        @include breakpoint-property(
            'margin-top',
            (
                s: 2rem,
                l: 2.5rem
            )
        );
    }
    &__buttons {
        margin-top: 3rem;
    }
}