.fact {
    &__headline {
        @include font-headline-1;
        margin-bottom: 3rem;
    }

    .text--headline-1,
    .text--headline-2,
    .text--headline-3,
    .text--headline-4,
    .text--headline-5,
    .text--headline-6 {
        margin-bottom: 3rem;
    }

    .grid__col {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }

        @include breakpoint-only(l) {
            margin-bottom: 0;
        }
    }

    &__slider {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        @include breakpoint(l) {
            width: calc(4 * 100% / 12);
            padding: 0;
        }
    }

    .slider {
        @include breakpoint-only(s) {
            width: calc(100% + 4.8rem);
            margin-left: -2.4rem;
            margin-right: -2.4rem;
            touch-action: pan-y;
        }

        @include breakpoint-only(m) {
            width: calc(100% + 10.2rem);
            margin-left: -5.1rem;
            margin-right: -5.1rem;
        }

        ul {
            padding: 0;
        }
    }
    
    [class*="spacer-"] .slider {
        @include breakpoint-only(s) {
            width: calc(100% + #{$container-padding-s * 2});
            margin-left: 0;
            margin-right: 0;
        }

        @include breakpoint-only(m) {
            width: calc(100% + #{$container-padding-m * 2});
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__button-wrapper {
        &--center,
        &--left {
            .button {
                display: block;
                margin-top: 4rem;

                @include breakpoint(l) {
                    margin-top: 3.4rem;
                }

                @include breakpoint-only(m) {
                    display: inline-block;
                }
            }
        }

        &--center {
            text-align: center;
        }

        &--left {
            text-align: left;
        }
    }

    .button {
        @include breakpoint-property(
            'width',
            (
                s: 100%,
                m: auto,
                l: 100%
            )
        );
    }

    .download-teasers {
        margin-top: 0;
    }

    .download-teaser {
        width: calc(50% - 2rem);
        margin: 0 2rem 0 0;
        float: left;
        max-width: 16rem;

        &__size,
        &__cta {
            margin-bottom: 0;
        }
    }

}
