.flyout-navigation {
    display: none;
    position: absolute;
    top: 14rem;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba($color-cool-grey-500, .8);
    color: $color-black-500;

    ul {
        list-style: none;
    }

    &__wrapper {
        position: relative;
        height: 62.5rem;
        max-height: calc(100vh - #{$header-height-desktop});
        padding-top: 6rem;
        background: $color-cool-grey-100;
    }

    &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0 2rem 2.5rem;
    }

    &__headline {
        font-size: $font-size-headline-4;
        line-height: $line-height-headline-4;
        font-weight: 500;
    }

    &__close-btn {
        width: 3rem;
        height: 3rem;
        background: none;

        svg {
            fill: none;
        }

        &:hover {
            .icon {
                @include transition(color);
                color: $color-red-500;
            }
        }
    }

    &__nav-wrapper {
        position: relative;

        &:after {
            pointer-events: none;
            position: absolute;
            bottom: 0;
            left: 2.5rem;
            content: '';
            height: 7.5rem;
            right: 4.5rem;
            background: linear-gradient(180deg, rgba($color-cool-grey-100, 0) 0%, $color-cool-grey-100 100%);
        }
    }

    &__nav-lvl-2,
    &__nav-lvl-3 {
        padding: 0 2.5rem 0;
        overflow-y: auto;
        margin: 0 3rem 0 0;

        scrollbar-color: $color-black-500 transparent;
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 3px;

            &-thumb {
                background: $color-black-500;
                border-radius: .2rem;
            }
        }

        ul {
            &.is-scrolling-ended {
                z-index: 2;
                position: relative;
            }
        }

        li {
            display: flex;
            position: relative;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__nav-lvl-2 {
        height: 50rem;
        max-height: calc(100vh - #{$header-height-desktop} - 12.5rem);

        ul {
            &:not(.has-active-item):hover {
                li:not(:hover) {
                    opacity: .35;
                }

                li:hover {
                    border-bottom: .1rem solid $color-black-500;
                }
            }

            &.has-active-item {
                li:not(.is-active) {
                    opacity: .35;

                    &:hover {
                        opacity: 1;
                        border-bottom: .1rem solid $color-black-500;
                    }
                }
            }
        }

        li {
            @include transition(opacity, border-bottom);
            cursor: pointer;
            padding: 0;
            font-size: $font-size-headline-6;
            line-height: $line-height-headline-6;
            font-weight: 500;
            min-height: 6.4rem;
            border-bottom: .1rem solid rgba($color-cool-grey-500, .25);

            &:first-child {
                border-top: .1rem solid rgba($color-cool-grey-500, .25);
            }

            &::after {
                content: '';
                display: block;
                border: .2rem solid;
                width: 1rem;
                height: 1rem;
                position: absolute;
                top: calc(50% - 0.5rem);
                right: 1.2rem;
                border-left: none;
                border-top: none;
                transform: translate3d(0, 0, 0) rotate(-45deg);
                @include transition(transform);
                will-change: transition;
            }

            &.flyout-navigation__nav-lvl-2--no-dropdown {
                &::after {
                    display: none;
                }
                a {
                    color: inherit;
                    text-decoration: none;
                    width: 100%;
                }
            }

            &.is-active {
                border-bottom: .1rem solid $color-black-500;

                &::after {
                    transform: translate3d(2rem, 0, 0) rotate(-45deg);
                }
            }
            > a  {
                padding: 2rem 0;
            }
            &.flyout-navigation__nav-lvl-2--has-dropdown span {
                padding: 2rem 5rem 2rem 0;
            }
        }
    }

    &__lvl-3 {
        display: none;

        &.is-active {
            display: block;
        }

        .button {
            position: absolute;
            bottom: 2.5rem;
            display: inline-block;
            margin-left: 2.5rem;
        }
    }

    &__nav-lvl-3 {
        height: 39rem;

        li {
            padding: 0;
            border-top: .1rem solid rgba($color-cool-grey-500, .25);
            break-inside: avoid-column;

            &:last-child {
                border-bottom: .1rem solid rgba($color-cool-grey-500, .25);
            }
        }

        ul {
            &.two-column-layout {
                column-count: 2;
                column-gap: 2.5rem;

                li:last-child {
                    border-bottom: none;
                }
            }
        }

        a {
            @include transition(color);
            text-decoration: none;
            color: $color-black-500;
            width: 100%;
            padding: 1.9rem 0;
            line-height: $line-height-paragraph-3;
            font-size: $font-size-paragraph-2;
            font-weight: $font-weight-regular;
            min-height: 6.7rem;

            &:hover {
                color: $color-red-500;
            }
        }
    }

    &__image {
        width: 100%;
        height: auto;
        padding-bottom: 2.2rem;
    }

    &__image-link {
        @include transition(color);
        display: inline-block;
        font-size: $font-size-link-medium;
        font-weight: 500;
        text-decoration: none;
        color: $color-black-500;
        margin-bottom: 2.8rem;

        span {
            margin-right: 1rem;
        }

        &:hover {
            color: $color-red-500;

            span {
                border-bottom: 0.2rem solid $color-red-500;
            }
        }
    }

    @include breakpoint(xl) {
        &.is-active {
            display: block;
        }
    }
}
