@mixin srgrid-generate-container-base($container-max-width) {
    max-width: $container-max-width;
    margin: 0 auto;
}

// sR Grid Container Feature
@mixin srgrid-generate-container-feature(
    $grid-container-class,
    $grid-container-max-widths
) {

    .#{$grid-container-class} {
        @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
            @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {

                @if map-has-key($grid-container-max-widths, $breakpoint-name) {
                    $container-max-width: map-get($grid-container-max-widths, $breakpoint-name);
                    @include srgrid-generate-container-base($container-max-width);
                }
            }
        }
    }
}
