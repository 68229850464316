.highlight {
    $highlight-root: &;

    &--has-image {
        @include breakpoint-property(
            'padding-bottom',
            (
                s: 18.4rem - 2rem,
                m: 38.4rem - 10rem,
                l: 51.2rem - 17.5rem,
                xl: 64rem - 17.5rem
            )
        );

        #{$highlight-root}__img {
            left: 0;
            right: 0;
            position: absolute;
            display: block;
            aspect-ratio: 2/1;
            object-fit: cover;
            width: 100%;
            @include breakpoint-property(
                'height',
                (
                    s: 18.4rem,
                    m: 38.4rem,
                    l: 51.2rem,
                    xl: 64rem
                )
            );
        }

        #{$highlight-root}__content {
            @include breakpoint-property(
                'top',
                (
                    s: 18.4rem - 2rem,
                    m: 38.4rem - 10rem,
                    l: 51.2rem - 17.5rem,
                    xl: 64rem - 17.5rem
                )
            );
        }
    }

    &__content {
        position: relative;
        background: $color-black-100;

        @include breakpoint-property(
            'padding',
            (
                s: 3rem 2.4rem,
                m: 4.5rem 3.5rem,
                l: 6rem 4rem,
                xl: 6rem 5rem
            )
        );

        h3 {
            @include font-headline-3;

            @include breakpoint-property(
                'margin',
                (
                    s: 0,
                    m: 0,
                    l: 1.5rem 0 0 0
                )
            );
        }

        p {
            @include font-paragraph-book-3;
            color: $color-black-400;

            @include breakpoint-property(
                'margin',
                (
                    s: 1.5rem 0 0 0,
                    m: 0,
                    l: 2rem 0 0 0
                )
            );
        }

        // IST NUR BUTTON KLICKBAR?
        a {
            @include breakpoint-property(
                'margin',
                (
                    s: 3rem 0 0 0,
                    m: 2.5rem 0 0 0,
                    l: 3.5rem 0 0 0
                )
            );
        }

        .container {
            display: flex;
            flex-direction: column;

            @include breakpoint(l) {
                flex-direction: row;
            }
        }

        .column {
            flex: 1;

            @include breakpoint-property(
                'padding',
                (
                    s: 0,
                    m: 0,
                    l: 0 3rem 0
                )
            );

            @include breakpoint(l) {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }

                &:not(:first-child) {
                    border-left: 1px solid $color-black-200;
                }
            }

            @include breakpoint-only(m) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 3.5rem 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $color-black-200;
                }

                &--left {
                    display: flex;
                    padding-right: 1.5rem;
                    align-items: center;

                    h3 {
                        padding: 0 2rem 0 1.5rem;
                    }

                    svg {
                        min-width: 3.6rem;
                    }
                }

                &--right {
                    padding-left: 1.5rem;
                }
            }

            @include breakpoint-only(s) {
                padding: 4rem 0;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $color-black-200;
                }

                &--left {
                    display: flex;
                    align-items: center;

                    h3 {
                        padding-left: 2rem;
                    }

                    .icon {
                        width: 3rem;
                        min-width: 3rem;
                    }
                }

                &--right {

                }
            }
        }
    }

    .link,
    .button {
        display: inline-block;
    }
}
