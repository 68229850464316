.button-link-block {
    display: inline;
    &.spacer-xxs,
    &.spacer-xs,
    &.spacer-s,
    &.spacer-m,
    &.spacer-l,
    &.spacer-xl {
        display: inline-block;
    }
}
