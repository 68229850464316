.app {
    display: flex;

    @include breakpoint-property(
        'padding',
        (
            s: 6rem 0,
            m: 8rem 0,
            l: 10rem 0
        )
    );

    &__container {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $app-background-color;
            z-index: -1;

            @include breakpoint-property(
                'margin',
                (
                    s: 0 -#{$container-padding-s},
                    m: 0 -#{$container-padding-m},
                    l: 0 -#{$container-padding-l},
                    xl: 0 -#{$container-padding-xl},
                    xxl: 0 -#{$container-padding-xxl}
                )
            );
        }
    }

    .grid__col_12 & {
        flex-direction: column;

        @include breakpoint(m) {
            flex-direction: row;
        }

        &__content {
            margin-top: 3rem;

            @include breakpoint-property(
                'margin-left',
                (
                    m: 11.5rem,
                    l: 14.5rem
                )
            );

            @include breakpoint(m) {
                margin-top: 0;
                align-self: center;
            }
        }

        &__copy {
            @include breakpoint-only(m) {
                padding-bottom: 3rem;
            }
        }
    }

    .grid__col_5_l,
    .grid__col_4_m,
    .grid__col_2_s & {
        flex-direction: column;

        &__content {
            margin-top: 3rem;
        }

        &__headline {
            @include breakpoint(m) {
                @include font-headline-3;
            }
        }
    }

    &__headline {
        @include font-headline-2;
    }

    &__copy {
        @include font-paragraph-book-3;
        padding: 1.5rem 0 4rem;
    }

    &__buttons {
        display: flex;

        a {
            text-decoration: none;

            &:first-of-type {
                margin-right: 1rem;
            }
        }
    }

    .link {
        text-decoration-thickness: 0.15rem;
    }

    &__img {
        display: block;
        height: auto;

        @include breakpoint-property(
            'width',
            (
                s: 23.5rem,
                m: 23.3rem,
                l: 31.6rem
            )
        );
    }
}
