.news {
    display: flex;
    flex-direction: column;

    .button {
        display: block;
        margin-right: 0;
    }

    .news-item + .button {
        margin-top: 3rem;

        @include breakpoint(m) {
            margin-top: 4rem;
            margin-left: auto;
        }
    }
}
