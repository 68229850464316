.newsletter {
    position: relative;
    padding: 6rem 0;
    color: $color-black-500;

    &:before {
        display: block;
        content: '';
        top: 0;
        bottom: 0;
        position: absolute;
        background-color: $newsletter-module-bg;
        z-index: -1;

        @include breakpoint-property(
            'left',
            (
                s: -#{$container-padding-s},
                m: -#{$container-padding-m},
                l: 0
            )
        );

        @include breakpoint-property(
            'right',
            (
                s: -#{$container-padding-s},
                m: 3rem,
                l: 4rem
            )
        );
    }

    &__content {
        @include breakpoint-only(m) {
            padding-right: 3rem;
        }
    }

    &__headline {
        @include font-headline-1;
        margin-top: 3rem;

        @include breakpoint(m) {
            margin-top: 0;
        }
    }

    &__copy {
        @include font-paragraph-book-3;
        margin: 2rem 0;
    }

    &__form {
        label {
            font-size: $newsletter-label-font-size;
            font-weight: 450;
            line-height: 160%;
        }

        .button,
        input {
            width: 100%;

            @include breakpoint(l) {
                width: 27.8rem;
            }
            @include breakpoint(xl) {
                width: 37rem;
            }
        }

        input {
            @include font-paragraph-book-3;
            display: block;
            border: none;
            border-bottom: 0.1rem solid $newsletter-input-border;
            background: none;
            margin-top: 0.5rem;

            &::placeholder {
                color: $color-black-300;
            }

            &:focus {
                outline: none;
            }
        }

        .button {
            display: inline-block;
            text-decoration: none;
            margin-top: 3rem;
        }
    }

    &__img {
        display: block;
        aspect-ratio: 3/4;
        width: 22rem;
        height: auto;

        @include breakpoint(m) {
            width: 100%;
        }
    }
}
