.stage {
    @include breakpoint-property(
        'padding-bottom',
        (
            s: calc(18.4rem - 2rem),
            m: calc(38.4rem - 4.5rem),
            l: calc(51.2rem - 7.5rem),
            xl: calc(64rem - 7.5rem)
        )
    );

    &__img {
        left: 0;
        right: 0;
        position: absolute;
        display: block;
        aspect-ratio: 2/1;
        object-fit: cover;
        width: 100%;
        @include breakpoint-property(
            'height',
            (
                s: 18.4rem,
                m: 38.4rem,
                l: 51.2rem,
                xl: 64rem
            )
        );
    }

    &__content {
        position: relative;
        background: $color-white;

        @include breakpoint-property(
            'padding',
            (
                s: 1rem 1.5rem 0,
                m: 2.5rem 3rem 0,
                l: 3rem 4rem 0
            )
        );

        @include breakpoint-property(
            'top',
            (
                s: calc(18.4rem - 2rem),
                m: calc(38.4rem - 4.5rem),
                l: calc(51.2rem - 7.5rem),
                xl: calc(64rem - 7.5rem)
            )
        );
        @include breakpoint-property(
            'left',
            (
                m: -3rem,
                l: -4rem
            )
        );
        @include breakpoint-property(
            'right',
            (
                m: -3rem,
                l: -4rem
            )
        );
        @include breakpoint-property(
            'width',
            (
                m: calc(100% + 3rem + 3rem),
                l: 66.2rem,
                xl: 87rem
            )
        );
    }

    h1,
    h2,
    .link,
    .button {
        display: inline-block;
    }

    h1 {
        @include font-headline-1;

        @include breakpoint(l) {
            @include font-display-1;
        }

        + h2 {
            @include font-headline-4;

            @include breakpoint(l) {
                @include font-headline-3;
            }

            @include breakpoint-property(
                'margin-top',
                (
                    s: 1rem,
                    m: 1.5rem
                )
            );
        }
    }

    .button,
    .link {
        margin-top: 2.8rem;

        @include breakpoint(l) {
            margin-top: 3rem;
        }
    }

    .link {
        word-break: break-word;
        font-weight: $font-weight-medium;
    }
}
