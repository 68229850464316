.badge {
    border: 1px solid $color-cool-grey-300;
    color: $color-cool-grey-400;
    padding: 0.2rem 0.8rem;
    @include font-paragraph-bold-4;
    line-height: 200%;
    border-radius: 1.2rem;
    margin-right: 1rem;
    text-decoration: none;
    white-space: nowrap;

    @include breakpoint(l) {
        @include font-paragraph-book-5;
        line-height: 200%;
    }

    &--variant {
        border: 1px solid $color-cool-grey-300;
        color: $color-cool-grey-400;
    }

    &--promotion {
        border: 1px solid $color-red-500;
        color: $color-red-500;
    }
}