@mixin srgrid-generate-fluid-feature-base(
    $class,
    $grid-column-count,
    $i
) {
    .#{$class} {
        $_width: round-percentage(100% / $grid-column-count * $i);

        flex-basis: $_width;
        max-width: $_width;
    }
}

// sR Grid Fluid Regular Feature
@mixin srgrid-generate-fluid-feature(
    $grid-column-class,
    $grid-column-count
) {
    @for $i from 1 through $grid-column-count {
        $class: srgrid-generate-regular-class($grid-column-class, $i);
        @include srgrid-generate-fluid-feature-base(
                $class,
                $grid-column-count,
                $i
        );
    }
}

// sR Grid Fluid Responsive Feature
@mixin srgrid-generate-fluid-responsive-feature(
    $grid-column-class,
    $grid-column-count
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            // Auto width
            $class: srgrid-generate-responsive-class($grid-column-class, 0, $breakpoint-name);
            @include srgrid-generate-fluid-feature-base(
                    $class,
                    1,
                    1
            );

            @for $i from 1 through $grid-column-count {
                $class: srgrid-generate-responsive-class($grid-column-class, $i, $breakpoint-name);
                @include srgrid-generate-fluid-feature-base(
                        $class,
                        $grid-column-count,
                        $i
                );
            }
        }
    }
}

// sR Grid Fluid Only Feature
@mixin srgrid-generate-fluid-only-feature(
    $grid-column-class,
    $grid-column-count
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            // Auto width
            $class: srgrid-generate-only-class($grid-column-class, 0, $breakpoint-name);
            @include srgrid-generate-fluid-feature-base(
                    $class,
                    1,
                    1
            );

            @for $i from 1 through $grid-column-count {
                $class: srgrid-generate-only-class($grid-column-class, $i, $breakpoint-name);
                @include srgrid-generate-fluid-feature-base(
                        $class,
                        $grid-column-count,
                        $i
                );
            }
        }
    }
}
