@mixin srgrid-generate-distribution-base(
    $around-class,
    $between-class
) {
    .#{$around-class} {
        justify-content: space-around;
    }

    .#{$between-class} {
        justify-content: space-between;
    }
}

// sR Grid Distribution Regular Feature
@mixin srgrid-generate-distribution-feature(
    $grid-distribution-around-class,
    $grid-distribution-between-class
) {
    $around-class: $grid-distribution-around-class;
    $between-class: $grid-distribution-between-class;
    @include srgrid-generate-distribution-base(
            $around-class,
            $between-class
    );
}

// sR Grid Distribution Responsive Feature
@mixin srgrid-generate-distribution-responsive-feature(
    $grid-distribution-around-class,
    $grid-distribution-between-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            $around-class: srgrid-generate-responsive-class($grid-distribution-around-class, null, $breakpoint-name);
            $between-class: srgrid-generate-responsive-class($grid-distribution-between-class, null, $breakpoint-name);
            @include srgrid-generate-distribution-base(
                    $around-class,
                    $between-class
            );
        }
    }
}

// sR Grid Distribution Only Feature
@mixin srgrid-generate-distribution-only-feature(
    $grid-distribution-around-class,
    $grid-distribution-between-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            $around-class: srgrid-generate-only-class($grid-distribution-around-class, null, $breakpoint-name);
            $between-class: srgrid-generate-only-class($grid-distribution-between-class, null, $breakpoint-name);
            @include srgrid-generate-distribution-base(
                    $around-class,
                    $between-class
            );
        }
    }
}
