// Round percentage number to three digits
// Example: 80.5555555556% => 80.556%
@function round-percentage ($percentage) {
    $n: 1;
    @for $i from 1 through 3 {
        $n: $n * 10;
    }

    @return round($percentage * $n) / $n;
}


// Generate Regular Class Names
@function srgrid-generate-regular-class(
    $base-class,
    $modifier
) {
    $class: $base-class;
    @if $modifier {
        $class: $class + $srgrid-bem-modifier-delimiter + $modifier;
    }

    @return $class;
}

// Generate Responsive Class Names
@function srgrid-generate-responsive-class(
    $base-class,
    $modifier,
    $breakpoint-name
) {
    $class: srgrid-generate-regular-class($base-class, $modifier);
    @if $breakpoint-name {
        $class: $class + $srgrid-bem-modifier-delimiter + $breakpoint-name;
    }

    @return $class;
}

// Generate Only Class Names
@function srgrid-generate-only-class(
    $base-class,
    $modifier,
    $breakpoint-name
) {

    $class: srgrid-generate-responsive-class($base-class, $modifier, $breakpoint-name);
    $class: $class + $srgrid-bem-modifier-delimiter + $srgrid-only-infix;

    @return $class;
}


// Wrap Content from a Specific Breakpoint (Mobile First Approach)
@mixin srgrid-content-breakpoint (
    $breakpoint-name,
    $grid-breakpoints: $srgrid-breakpoints
) {
    @if map-has-key($grid-breakpoints, $breakpoint-name) == false {
        @error 'Breakpoint “#{$breakpoint-name}” was not found!';
    }

    @each $breakpoint, $breakpoint-width in $grid-breakpoints {
        @if $breakpoint-name == $breakpoint {
            @media (min-width: $breakpoint-width) {
                @content;
            }
        }
    }
}


// Wrap Content within a Specific Breakpoint
@mixin srgrid-content-breakpoint-only(
    $breakpoint-name,
    $grid-breakpoints: $srgrid-breakpoints
) {
    @if map-has-key($grid-breakpoints, $breakpoint-name) == false {
        @error 'Breakpoint “#{$breakpoint-name}” was not found!';
    }

    $breakpoints: map-keys($grid-breakpoints);
    $breakpoint-widths: map-values($grid-breakpoints);

    @for $index from 1 through length($grid-breakpoints) {
        $breakpoint: nth($breakpoints, $index);
        $breakpoint-width: nth($breakpoint-widths, $index);

        @if $breakpoint-name == $breakpoint {
            @if $index == 1 {
                $next-breakpoint-width: nth($breakpoint-widths, $index + 1) - 1;

                @media (max-width: $next-breakpoint-width) {
                    @content;
                }
            } @else if $index == length($grid-breakpoints) {
                @media (min-width: $breakpoint-width) {
                    @content;
                }
            } @else {
                $next-breakpoint-width: nth($breakpoint-widths, $index + 1) - 1;

                @media (min-width: $breakpoint-width) and (max-width: $next-breakpoint-width) {
                    @content;
                }
            }
        }
    }
}
