.simple-teaser {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    @include breakpoint-property(
            'margin',
            (
                s: 0 0 -4rem 0,
                m: 0 -1.5rem -4rem -1.5rem,
                l: 0 -2.5rem -5rem -2.5rem,
                xl: 0 -3.75rem -5rem -3.75rem
            )
    );
}
