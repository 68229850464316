.job-teaser-listing {
    &__item {
        padding: 4rem 0;
        border-bottom: 1px solid $color-black-100;
    }

    &__label {
        color: $color-red-500;
        @include breakpoint-property(
            'margin-bottom',
            (
                s: 1.5rem,
                m: 2rem
            )
        );
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__description {
        margin-bottom: 2rem;
    }

    &__more-link {
        text-align: right;
        @include breakpoint-property(
            'padding-top',
            (
                s: 3rem,
                m: 4rem
            )
        );
        &__link {
            &.button {
                margin-right: 0;
            }
            @include breakpoint-property(
                'width',
                (
                    s: 100%,
                    m: auto
                )
            );  
        }
    }
    
}