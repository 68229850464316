.accordion {
    $accordion-root: &;
    
    &__header {
        margin-bottom: 5rem;
    }

    &__menu {
        position: relative;
    }

    &__close,
    &__title {
        min-height: 7.4rem;
        width: 100%;
    }

    &__close {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
    }

    &__title {
        display: flex;
        align-items: center;
        padding: 2.5rem 4rem 2.5rem 0;
        position: relative;
        @include font-headline-4;
        border-bottom: 0.1rem solid transparent;
        cursor: pointer;

        @include breakpoint-property(
            'padding',
            (
                m: 2.5rem 4rem 2.5rem 0,
                l: 3rem 4rem 3rem 0
            )
        );

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                &,
                & ~ #{$accordion-root}__content {
                    border-color: $accordion-divider-border-active;
                }
            }
        }

        &::after,
        &::before {
            content: '';
            display: block;
            aspect-ratio: 1;
            position: absolute;
            background: $color-black-500;
        }

        &::before {
            top: calc(50% - 1rem);
            right: 0.9rem;
            width: 0.2rem;
            height: 2rem;
        }

        &::after {
            top: calc(50% - 0.1rem);
            right: 0;
            width: 2rem;
            height: 0.2rem;
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        border-bottom: 0.1rem solid $accordion-divider-border;

        .slider {
            ul {
                padding: 0;
            }
        }

        .editor-content {
            margin-bottom: 2.5rem;

            > .image-block,
            > .slider,
            > .video,
            > .download-teasers,
            > .product-big-tiles,
            > .headline-copy__content {
                @include breakpoint-property(
                    'margin-bottom',
                    (
                        s: 5rem,
                        l: 7rem
                    )
                );
            }
        }
    }

    input:checked {
        ~ #{$accordion-root} {
            &__title::before {
                display: none;
            }

            &__close {
                display: block;
            }

            &__content {
                height: auto;
                border-bottom: 0.2rem solid $accordion-divider-border-active;
            }
        }
    }
}
