$font-size-link-medium: 1.8rem;
$font-size-link-small: 1.6rem;
$line-height-link-medium: 150%;


.link,
.editor-content .link {
    color: $color-black-500;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-thickness: 0.2rem;
    text-underline-offset: 0.7rem;
    text-decoration-color: transparent;
    @include transition(color, text-decoration);

    &:hover {
        color: $color-red-500;
        text-decoration-color: $color-red-500;
    }

    &:focus,
    &:active {
        color: $color-cool-grey-500;
        text-decoration-color: $color-cool-grey-500;
    }

    &[disabled],
    &.disabled {
        color: $color-black-300;
        text-decoration-color: $color-black-300;
        pointer-events: none;
    }

    &--medium {
        font-size: $font-size-link-medium;
        line-height: $line-height-link-medium;
        &--underlined {
            font-size: $font-size-link-medium;
            line-height: $line-height-link-medium;
            text-decoration: underline;
            text-decoration-thickness: 0.2rem;
            text-underline-offset: 0.7rem;
            text-decoration-color: currentColor;
        }
    }

    &--small {
        font-size: $font-size-link-small;
        text-decoration: underline;
        text-decoration-thickness: 0.2rem;
        text-underline-offset: 0.5rem;
        text-decoration-color: transparent;
        &--underlined {
            font-size: $font-size-link-small;
            text-decoration: underline;
            text-decoration-thickness: 0.2rem;
            text-underline-offset: 0.5rem;
            text-decoration-color: currentColor;
        }
    }

    &--underlined {
        text-decoration: underline;
        text-decoration-thickness: 0.2rem;
        text-underline-offset: 0.5rem;
        text-decoration-color: currentColor;
    }
}

html {
	scroll-behavior: smooth;
}

a[id] {
    position: relative;
    top: -$header-height-mobile;
    @include breakpoint-property(
        'top',
        (
            m: -$header-height-tablet,
            xl: -$header-height-desktop
        )
    );
}
