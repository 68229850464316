@mixin srgrid-generate-hide-base(
    $class
) {
    .#{$class} {
        display: none;
    }
}


// sR Grid Hide Regular Feature
@mixin srgrid-generate-hide-feature(
    $grid-hide-class
) {
    .#{$grid-hide-class} {
        display: none;
    }
}

// sR Grid Hide Responsive Feature
@mixin srgrid-generate-hide-responsive-feature(
    $grid-hide-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            $class: srgrid-generate-responsive-class($grid-hide-class, null, $breakpoint-name);
            @include srgrid-generate-hide-base(
                    $class
            );
        }
    }
}

// sR Grid Hide Only Feature
@mixin srgrid-generate-hide-only-feature(
    $grid-hide-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            $class: srgrid-generate-only-class($grid-hide-class, null, $breakpoint-name);
            @include srgrid-generate-hide-base(
                    $class
            );
        }
    }
}
