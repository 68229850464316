.hero-teaser {
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        left: -#{$container-padding-s};
        content:'';
        height: 9.9rem;
        width: 19.9rem;
        background-color: $color-cool-grey-100;
        z-index: 0;

        @include breakpoint(m) {
            top: 2rem;
            left: -#{$container-padding-m};
            width: 100%;
            height: 10.6rem;
        }

        @include breakpoint(l) {
            top: 3rem;
            height: 15rem;
            left: -#{$container-padding-l};
        }

        @include breakpoint(xl) {
            left: -#{$container-padding-xl};
            height: 19.8rem;
        }
    }

    &__link {
        color: $color-black-500;
        text-decoration: none;
        @include aspect-ratio-fallback();
    }

    &__text {
        transform-style: preserve-3d;
        background-color: $color-white;
        font-size: 2.2rem;
        line-height: 2.8rem;
        font-weight: 500;
        margin-top: 2.5rem;
        @include transition(color);

        .hero-teaser__link--is-external & {
            background-color: $color-cool-grey-100;
            @include breakpoint(s) {
                padding: 3rem 1rem 1rem 24px;
                position: relative;
                top: -5rem;
                left: -24px;
            }
            @include breakpoint(m) {
                padding: 2rem 2.5rem;
                position: static;
            }

            
        }

        &:hover,
        &:focus {
            color: $color-red-500;

            h3 {
                text-decoration: underline;
                text-underline-offset: 0.8rem;

                @include breakpoint(l) {
                    text-underline-offset: 1rem;
                }
            }

            .svg-arrow {
                &:after {
                    content: '';
                    background: no-repeat left url("../../images/arrow-right-large.svg");
                    width: 4.2rem;
                    height: 2.2rem;
                    display: block;
                }
            }
        }

        .svg-arrow {
            &:after {
                content: '';
                background: no-repeat left url("../../images/arrow-right-medium.svg");
                width: 2.7rem;
                height: 2.2rem;
                display: block;
                transition: 250ms ease-in-out;
                -moz-transition: 180ms ease-in-out;
            }
        }

        h3,
        p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
        }

        h3 {
            font-size: 3.6rem;
            line-height: 3.9rem;
            font-weight: 500;
            padding-bottom: 1.5rem;
        }

        p {
            margin-bottom: 2.9rem;
        }

        @include breakpoint(m) {
            margin: 3.5rem 0 0 -2.5rem;
            padding: 2rem 2.5rem;
            font-size: 2.4rem;
            line-height: 3.1rem;

            &::after {
                content: '';
                position: absolute;
                bottom: -2.0rem;
                right: -1.5rem;
                height: 15rem;
                width: 100%;
                background: transparent;
                transform: translateZ(-1px);
                border-style: solid;
                border-color: $color-cool-grey-100;
                border-width: 0 1.5rem 2rem 0
            }

            h3 {
                font-size: $font-size-headline-2-desktop;
            }

            p {
                margin-bottom: 2.4rem;
            }
        }

        @include breakpoint-only(m) {
            max-width: 30.4rem;

            &::after {
                max-width: 26rem;
            }
        }

        @include breakpoint(l) {
            margin: 4.5rem -2.5rem 0 -3.5rem;
            padding: 3rem;
            line-height: 3.6rem;
            font-size: 2.8rem;

            h3 {
                font-size: 4.8rem;
                line-height: 5.2rem;
            }

            &::after {
                max-width: 35rem;
            }
        }

        @include breakpoint(xl) {
            margin: 4.5rem -3.5rem 0 -4.5rem;
            padding: 3rem 4rem;
            &::after {
                max-width: 53.6rem;
            }
        }
    }

    img {
        position: relative;
        width: calc(100% + #{$container-padding-s});
        height: auto;

        @include breakpoint(m) {
            position: absolute;
            top: 0;
            right: -5rem;
        }

        @include breakpoint(l) {
            right: -#{$container-padding-l};
        }

        @include breakpoint(xl) {
            right: -#{$container-padding-xl};
        }

        @include breakpoint-property(
            'width',
            (
                m: calc(7 * 100% / 8 + 5rem),
                l: calc(10 * 100% / 12 + #{$container-padding-l} + #{$grid-gutter-widths}),
                xl: calc(11 * 100% / 12 + #{$container-padding-xl} - 2rem)
            )

        );

    }

    @include breakpoint-only(s) {
        padding-top: 1.5rem;
    }

    @include breakpoint(m) {
        padding-bottom: 2rem;
    }

    @include breakpoint-property(
            'aspect-ratio',
            (
                m: 61/30,
                l: 195/100,
                xl: 16/9
            )
    );
}
