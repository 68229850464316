.notification {
    display: flex;
    overflow: hidden;
    color: $color-black-500;
    background-color: $color-gold-300;
    @include breakpoint-property(
                    'margin',
                    (
                            s: 0 -#{$container-padding-s},
                            m: 0 -#{$container-padding-m},
                            l: 0 -#{$container-padding-l},
                            xl: 0 -#{$container-padding-xl},
                            xxl: 0 -#{$container-padding-xxl}
                    )
    );

    @include breakpoint-property(
                    'padding',
                    (
                            s: 0 #{$container-padding-s},
                            m: 0 #{$container-padding-m},
                            l: 0 #{$container-padding-l},
                            xl: 0 #{$container-padding-xl},
                            xxl: 0 #{$container-padding-xxl}
                    )
    );

    @include breakpoint(l) {
        justify-content: center;
    }

    &--success {
        &,
        .editor-content {
            color: $notification-success-text-color;
            background-color: $color-success-200;

            a {
                color: $notification-success-text-color;
            }
        }
    }

    &--error {
        &,
        .editor-content {
            color: $notification-error-text-color;
            background-color: $color-error-200;

            a {
                color: $notification-error-text-color;
            }
        }

        svg {
            color: $color-error-500;
        }
    }

    &--warning {
        &,
        .editor-content {
            color: $notification-warning-text-color;
            background-color: $color-warning-200;

            a {
                color: $notification-warning-text-color;
            }
        }

        svg {
            color: $notification-warning-icon-color;
        }
    }

    &--info {
        &,
        .editor-content {
            color: $notification-info-text-color;
            background-color: $color-kristall-200;

            a {
                color: $notification-info-text-color;
            }
        }
    }

    &--promo {
        &,
        .editor-content {
            color: $color-black-500;
            background-color: $color-gold-300;

            a {
                text-decoration-thickness: 0.15rem;
            }
        }
    }

    p,
    .icon {
        margin-top: 1.8rem;
        margin-bottom: 1.8rem;
    }

    .icon {
        margin-right: 1.7rem;
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        min-width: fit-content;
    }

    .editor-content {
        p,
        ol, 
        ul,
        dl,
        dt,
        dd,
        blockquote,
        address,
        table {
            margin-bottom: 1.8rem;
        }
        a {
            text-decoration-thickness: 0.15rem;
        }
    }

    &.fade-out {
        animation-name: fadeOut;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes fadeOut {
        to {
            height: 0;
            opacity: 0;
        }
    }

    p {
        font-size: $font-size-paragraph-3;
        line-height: $line-height-paragraph-1;
        font-weight: 450;
    }
}
