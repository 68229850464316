@mixin srgrid-generate-offset-feature-base(
    $class,
    $grid-column-count,
    $i
) {
    .#{$class} {
        $margin-left: round-percentage(100% / $grid-column-count * $i);
        margin-left: $margin-left;
    }
}

// sR Grid Offset Regular Feature
@mixin srgrid-generate-offset-feature(
    $grid-offset-class,
    $grid-column-count
) {
    @for $i from 1 through $grid-column-count - 1 {
        $class: srgrid-generate-regular-class($grid-offset-class, $i);
        @include srgrid-generate-offset-feature-base(
                $class,
                $grid-column-count,
                $i
        );
    }
}

// sR Grid Offset Responsive Feature
@mixin srgrid-generate-offset-responsive-feature(
    $grid-offset-class,
    $grid-column-count
) {
    @each $breakpoint-name, $breakpoint-width in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            // Reset offset
            $class: srgrid-generate-responsive-class($grid-offset-class, 0, $breakpoint-name);
            .#{$class} {
                margin-left: 0;
            }

            @for $i from 1 through $grid-column-count {
                $class: srgrid-generate-responsive-class($grid-offset-class, $i, $breakpoint-name);
                @include srgrid-generate-offset-feature-base(
                        $class,
                        $grid-column-count,
                        $i
                );
            }
        }
    }
}

// sR Grid Offset Only Feature
@mixin srgrid-generate-offset-only-feature(
    $grid-offset-class,
    $grid-column-count
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            // Reset offset
            $class: srgrid-generate-only-class($grid-offset-class, 0, $breakpoint-name);
            .#{$class} {
                margin-left: 0;
            }

            @for $i from 1 through $grid-column-count {
                $class: srgrid-generate-only-class($grid-offset-class, $i, $breakpoint-name);
                @include srgrid-generate-offset-feature-base(
                        $class,
                        $grid-column-count,
                        $i
                );
            }
        }
    }
}
