@mixin srgrid-generate-reorder-base(
    $class,
    $order
) {
    .#{$class} {
        order: #{$order};
    }
}

// sR Grid Reorder Regular Feature
@mixin srgrid-generate-reorder-feature(
    $grid-reorder-class,
    $grid-column-count
) {
    @for $order from 0 through $grid-column-count {
        $class: srgrid-generate-regular-class($grid-reorder-class, $order);
        @include srgrid-generate-reorder-base(
                $class,
                $order
        );
    }
}

// sR Grid Reorder Responsive Feature
@mixin srgrid-generate-reorder-responsive-feature(
    $grid-reorder-class,
    $grid-column-count
) {

    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            @for $order from 0 through $grid-column-count {
                $class: srgrid-generate-responsive-class($grid-reorder-class, $order, $breakpoint-name);
                @include srgrid-generate-reorder-base(
                        $class,
                        $order
                );
            }
        }
    }
}

// sR Grid Reorder Only Feature
@mixin srgrid-generate-reorder-only-feature(
    $grid-reorder-class,
    $grid-column-count
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            @for $order from 0 through $grid-column-count {
                $class: srgrid-generate-only-class($grid-reorder-class, $order, $breakpoint-name);
                @include srgrid-generate-reorder-base(
                        $class,
                        $order
                );
            }
        }
    }
}
