@mixin srgrid-generate-align-column-base(
    $grid-column-top-class,
    $grid-column-middle-class,
    $grid-column-bottom-class,
    $grid-column-stretch-class
) {
    .#{$grid-column-top-class} {
        align-self: flex-start;
    }

    .#{$grid-column-middle-class} {
        align-self: center;
    }

    .#{$grid-column-bottom-class} {
        align-self: flex-end;
    }

    .#{$grid-column-stretch-class} {
        align-self: stretch;
    }
}

// sR Grid Align Column Regular Feature
@mixin srgrid-generate-align-column-feature(
    $grid-align-column-top-class,
    $grid-align-column-middle-class,
    $grid-align-column-bottom-class,
    $grid-align-column-stretch-class
) {
    $grid-column-top-class:$grid-align-column-top-class;
    $grid-column-middle-class:$grid-align-column-middle-class;
    $grid-column-bottom-class: $grid-align-column-bottom-class;
    $grid-column-stretch-class: $grid-align-column-stretch-class;
    @include srgrid-generate-align-column-base(
        $grid-column-top-class,
        $grid-column-middle-class,
        $grid-column-bottom-class,
        $grid-column-stretch-class
    );
}

//sR Grid Align Column Responsive Feature
@mixin srgrid-generate-align-column-responsive-feature(
    $grid-align-column-top-class,
    $grid-align-column-middle-class,
    $grid-align-column-bottom-class,
    $grid-align-column-stretch-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            $grid-column-top-class: srgrid-generate-responsive-class($grid-align-column-top-class, null, $breakpoint-name);
            $grid-column-middle-class: srgrid-generate-responsive-class($grid-align-column-middle-class, null, $breakpoint-name);
            $grid-column-bottom-class: srgrid-generate-responsive-class($grid-align-column-bottom-class, null, $breakpoint-name);
            $grid-column-stretch-class: srgrid-generate-responsive-class($grid-align-column-stretch-class, null, $breakpoint-name);
            @include srgrid-generate-align-column-base(
                $grid-column-top-class,
                $grid-column-middle-class,
                $grid-column-bottom-class,
                $grid-column-stretch-class
            );
        }
    }
}

//sR Grid Align Column Only Feature
@mixin srgrid-generate-align-only-column-feature(
    $grid-align-column-top-class,
    $grid-align-column-middle-class,
    $grid-align-column-bottom-class,
    $grid-align-column-stretch-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            $grid-column-top-class: srgrid-generate-only-class($grid-align-column-top-class, null, $breakpoint-name);
            $grid-column-middle-class: srgrid-generate-only-class($grid-align-column-middle-class, null, $breakpoint-name);
            $grid-column-bottom-class: srgrid-generate-only-class($grid-align-column-bottom-class, null, $breakpoint-name);
            $grid-column-stretch-class: srgrid-generate-only-class($grid-align-column-stretch-class, null, $breakpoint-name);
            @include srgrid-generate-align-column-base(
                $grid-column-top-class,
                $grid-column-middle-class,
                $grid-column-bottom-class,
                $grid-column-stretch-class
            );
        }
    }
}
