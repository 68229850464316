@mixin srgrid-generate-column-fractions-base(
    $class,
    $grid-column-class,
    $grid-gutter-widths,
    $width
) {
    $width: round-percentage($width);

    .#{$class} {
        @include srgrid-generate-grid-column(
            $grid-column-class,
            $grid-gutter-widths
        );

        flex-basis: $width;
        max-width: $width;
    }
}

// sR Grid Column Fractions Regular Feature
@mixin srgrid-generate-column-fractions-feature(
    $grid-column-class,
    $grid-column-fractions,
    $grid-gutter-widths
) {
    @each $fraction in $grid-column-fractions {
        $numerator: nth($fraction, 1);
        $denominator: nth($fraction, 2);
        $width: $numerator / $denominator * 100%;

        $class: srgrid-generate-regular-class($grid-column-class, $numerator + $srgrid-bem-long-term-delimiter + $denominator);
        @include srgrid-generate-column-fractions-base(
            $class,
            $grid-column-class,
            $grid-gutter-widths,
            $width
        );
    }
}

// sR Grid Column Fractions Responsive Feature
@mixin srgrid-generate-column-fractions-responsive-feature(
    $grid-column-class,
    $grid-column-fractions,
    $grid-gutter-widths
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            @each $fraction in $grid-column-fractions {
                $numerator: nth($fraction, 1);
                $denominator: nth($fraction, 2);
                $width: $numerator / $denominator * 100%;

                $class: srgrid-generate-responsive-class(
                    $grid-column-class,
                    $numerator + $srgrid-bem-long-term-delimiter + $denominator,
                    $breakpoint-name
                );
                @include srgrid-generate-column-fractions-base(
                        $class,
                        $grid-column-class,
                        $grid-gutter-widths,
                        $width
                );
            }
        }
    }
}


// sR Grid Column Fractions Only Feature
@mixin srgrid-generate-column-fractions-only-feature(
    $grid-column-class,
    $grid-column-fractions,
    $grid-gutter-widths
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            @each $fraction in $grid-column-fractions {
                $numerator: nth($fraction, 1);
                $denominator: nth($fraction, 2);
                $width: $numerator / $denominator * 100%;

                $class: srgrid-generate-only-class(
                    $grid-column-class,
                    $numerator + $srgrid-bem-long-term-delimiter + $denominator,
                    $breakpoint-name
                );
                @include srgrid-generate-column-fractions-base(
                        $class,
                        $grid-column-class,
                        $grid-gutter-widths,
                        $width
                );
            }
        }
    }
}
