@mixin srgrid-generate-reverse-base(
    $class
) {
    .#{$class} {
        flex-direction: row-reverse;
    }
}

// sR Grid Reverse Regular Feature
@mixin srgrid-generate-reverse-feature(
    $grid-reverse-class
) {
    $class: $grid-reverse-class;
    @include srgrid-generate-reverse-base($class);
}

// sR Grid Reverse Responsive Feature
@mixin srgrid-generate-reverse-responsive-feature(
    $grid-reverse-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        $class: srgrid-generate-responsive-class($grid-reverse-class, null, $breakpoint-name);
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            @include srgrid-generate-reverse-base(
                $class
            );
        }
    }
}

// sR Grid Reverse Only Feature
@mixin srgrid-generate-reverse-only-feature(
    $grid-reverse-class
) {

    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        $class: srgrid-generate-only-class($grid-reverse-class, null, $breakpoint-name);
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            @include srgrid-generate-reverse-base(
                    $class
            );
        }
    }
}
