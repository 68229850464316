$font-size-button-medium: 1.8rem;
$font-size-button-small: 1.6rem;
$line-height-button-medium: 2.2rem;
$line-height-button-small: 2rem;

.button {
    font-weight: $font-weight-medium;
    text-decoration: none;
    margin-right: 2rem;
    display: inline-block;

    + .link,
    + .button {
        @include breakpoint-only(s) {
            margin-top: 2rem;
            display: table;
        }
    }

    + .button {
        margin-right: 4rem;
    }

    .link {
        margin-top: 2.8rem;

        @include breakpoint(l) {
            margin-top: 3rem;
        }
    }

    &--primary-medium,
    &--secondary-medium,
    &--primary-small,
    &--secondary-small {
        border: none;
        text-align: center;
        transition: 250ms background-color, 250ms color, 250ms border-color;

        .icon--arrow-right {
            margin-left: 14px;
            vertical-align: middle;
        }
    }

    &--primary-medium,
    &--secondary-medium {
        letter-spacing: $letter-spacing-m;
    }

    &--primary-medium,
    &--secondary-medium,
    &--link-icon-right-medium,
    &--link-icon-left-medium {
        font-size: $font-size-button-medium;
        line-height: $line-height-button-medium;
    }

    &--primary-small,
    &--secondary-small,
    &--link-icon-right-small,
    &--link-icon-left-small {
        font-size: $font-size-button-small;
        line-height: $line-height-button-small;
    }

    &--primary-medium,
    &--primary-small {
        color: $color-white;
        background: $color-black-500;
        padding: 14px 20px;
        text-decoration: none;

        &:hover {
            background-color: $color-red-500;
        }
        &:focus,
        &:active {
            background-color: $color-cool-grey-500;
        }

        &[disabled],
        &.disabled {
            background-color: $color-black-300;
            pointer-events: none;
        }
    }

    &--secondary-medium,
    &--secondary-small {
        color: $color-black-500;
        background: transparent;
        border: 2px solid $color-black-500;
        padding: 12px 20px;
        text-decoration: none;

        &:hover {
            color: $color-red-500;
            border-color: $color-red-500;
        }
        &:focus,
        &:active {
            color: $color-cool-grey-500;
            border-color: $color-cool-grey-500;
        }

        &[disabled],
        &.disabled {
            color: $color-black-300;
            border-color: $color-black-300;
            pointer-events: none;
        }
    }

    &--primary-small,
    &--secondary-small {
        padding: 10px 18px;
    }

    &--link-icon-right-medium,
    &--link-icon-right-small,
    &--link-icon-left-medium,
    &--link-icon-left-small {
        color: $color-black-500;
        background: none;

        &:hover {
            color: $color-red-500;
            border-bottom: 2px solid;
        }
        &:focus,
        &:active {
            color: $color-cool-grey-500;
            border-bottom: 2px solid;
        }

        &[disabled],
        &.disabled {
            color: $color-black-300;
            pointer-events: none;
        }

        &--underline {
            border-bottom: 2px solid;
        }
    }

    &--link-icon-right-medium,
    &--link-icon-right-small {
        .icon--arrow-right-small {
            margin-left: 9px;
        }
    }

    &--link-icon-left-medium,
    &--link-icon-left-small {
        .icon--arrow-right-small {
            margin-right: 9px;
        }
    }
}
