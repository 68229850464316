@mixin srgrid-generate-alignment-base(
    $start-class,
    $center-class,
    $end-class,
    $top-class,
    $middle-class,
    $bottom-class,
    $stretch-class
) {
    .#{$start-class} {
        justify-content: flex-start;
        text-align: left;
        text-align: start;
    }

    .#{$center-class} {
        justify-content: center;
        text-align: center;
    }

    .#{$end-class} {
        justify-content: flex-end;
        text-align: right;
        text-align: end;
    }

    .#{$top-class} {
        align-items: flex-start;
    }

    .#{$middle-class} {
        align-items: center;
    }

    .#{$bottom-class} {
        align-items: flex-end;
    }

    .#{$stretch-class} {
        align-items: stretch;
    }
}

// sR Grid Alignment Regular Feature
@mixin srgrid-generate-alignment-feature(
    $grid-alignment-start-class,
    $grid-alignment-center-class,
    $grid-alignment-end-class,
    $grid-alignment-top-class,
    $grid-alignment-middle-class,
    $grid-alignment-bottom-class,
    $grid-alignment-stretch-class
) {
    $start-class: $grid-alignment-start-class;
    $center-class: $grid-alignment-center-class;
    $end-class: $grid-alignment-end-class;
    $top-class: $grid-alignment-top-class;
    $middle-class: $grid-alignment-middle-class;
    $bottom-class: $grid-alignment-bottom-class;
    $stretch-class: $grid-alignment-stretch-class;
    @include srgrid-generate-alignment-base(
            $start-class,
            $center-class,
            $end-class,
            $top-class,
            $middle-class,
            $bottom-class,
            $stretch-class
    );
}

// sR Grid Alignment Responsive Feature
@mixin srgrid-generate-alignment-responsive-feature(
    $grid-alignment-start-class,
    $grid-alignment-center-class,
    $grid-alignment-end-class,
    $grid-alignment-top-class,
    $grid-alignment-middle-class,
    $grid-alignment-bottom-class,
    $grid-alignment-stretch-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint($breakpoint-name, $srgrid-breakpoints) {
            $start-class: srgrid-generate-responsive-class($grid-alignment-start-class, null, $breakpoint-name);
            $center-class: srgrid-generate-responsive-class($grid-alignment-center-class, null, $breakpoint-name);
            $end-class: srgrid-generate-responsive-class($grid-alignment-end-class, null, $breakpoint-name);
            $top-class: srgrid-generate-responsive-class($grid-alignment-top-class, null, $breakpoint-name);
            $middle-class: srgrid-generate-responsive-class($grid-alignment-middle-class, null, $breakpoint-name);
            $bottom-class: srgrid-generate-responsive-class($grid-alignment-bottom-class, null, $breakpoint-name);
            $stretch-class: srgrid-generate-responsive-class($grid-alignment-stretch-class, null, $breakpoint-name);
            @include srgrid-generate-alignment-base(
                    $start-class,
                    $center-class,
                    $end-class,
                    $top-class,
                    $middle-class,
                    $bottom-class,
                    $stretch-class
            );
        }
    }
}

// sR Grid Alignment Only Feature
@mixin srgrid-generate-alignment-only-feature(
    $grid-alignment-start-class,
    $grid-alignment-center-class,
    $grid-alignment-end-class,
    $grid-alignment-top-class,
    $grid-alignment-middle-class,
    $grid-alignment-bottom-class,
    $grid-alignment-stretch-class
) {
    @each $breakpoint-name, $breakpoint-widths in $srgrid-breakpoints {
        @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
            $start-class: srgrid-generate-only-class($grid-alignment-start-class, null, $breakpoint-name);
            $center-class: srgrid-generate-only-class($grid-alignment-center-class, null, $breakpoint-name);
            $end-class: srgrid-generate-only-class($grid-alignment-end-class, null, $breakpoint-name);
            $top-class: srgrid-generate-only-class($grid-alignment-top-class, null, $breakpoint-name);
            $middle-class: srgrid-generate-only-class($grid-alignment-middle-class, null, $breakpoint-name);
            $bottom-class: srgrid-generate-only-class($grid-alignment-bottom-class, null, $breakpoint-name);
            $stretch-class: srgrid-generate-only-class($grid-alignment-stretch-class, null, $breakpoint-name);
            @include srgrid-generate-alignment-base(
                    $start-class,
                    $center-class,
                    $end-class,
                    $top-class,
                    $middle-class,
                    $bottom-class,
                    $stretch-class
            );
        }
    }
}
