.EPiServerForms {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    color: $color-black-500;
    font-size: 1.6rem;

    .Form__Title {
        margin-bottom: 2rem;
    }

    .Form__Status {
        .Form__Title,
        .Form__Status__Message {
            &:empty,
            &.hide {
                display: none;
            }
        }

        .Form__Status__Message {
            font-size: $font-size-paragraph-3;
            line-height: $line-height-paragraph-1;
            font-weight: 450;
            padding: 1.5rem 2.4rem !important;
            margin-bottom: 3rem !important;
            background-color: $color-gold-300;
            color: $color-black-500;
            position: relative;
            word-break: break-word;
            @include breakpoint-property(
                'scroll-margin-top',
                (
                    s: 2rem,
                    m: 10rem,
                    l: 17.5rem,
                    xl: 17.5rem
                )
            );
            a {
                color: inherit;
                cursor: pointer;
                text-decoration: underline;
                text-decoration-thickness: 0.2rem;
                text-underline-offset: 0.4rem;
                @include transition(color, text-decoration);
                &:hover {
                    color: $color-black-500;
                    text-decoration-color: $color-black-500;
                }

                &:focus,
                &:active {
                    color: $color-cool-grey-500;
                    text-decoration-color: $color-cool-grey-500;
                }
            }

            &.Form__Success__Message {
                color: $notification-success-text-color;
                background-color: $color-success-200;
                padding-left: 6.2rem !important;    
                &:before {
                    content: '';
                    background: no-repeat left url("../../images/icons-bg/success.svg");
                    background-size: contain;
                    width: 2.4rem;
                    height: 2.4rem;
                    display: inline-block;
                    position: absolute;
                    left: 2.4rem;
                }
            }
            &.Form__Error__Message {
                color: $notification-error-text-color;
                background-color: $color-error-200;
                padding-left: 6.2rem !important;
                &:before {
                    content: '';
                    background: no-repeat left url("../../images/icons-bg/error.svg");
                    width: 2.6rem;
                    height: 2.4rem;
                    display: inline-block;
                    position: absolute;
                    left: 2.4rem;
                }
            }
            &.Form__Warning__Message {
                color: $notification-warning-text-color;
                background-color: $color-warning-300;
                padding-left: 6.2rem !important;        
                &:before {
                    content: '';
                    background: no-repeat left url("../../images/icons-bg/warning.svg");
                    width: 2.6rem;
                    height: 2.4rem;
                    display: inline-block;
                    position: absolute;
                    left: 2.4rem;
                    
                }
            }
            &.Form__Info__Message {
                color: $notification-info-text-color;
                background-color: $color-kristall-200;
                padding-left: 6.2rem !important;
                &:before {
                    content: '';
                    background: no-repeat left url("../../images/icons-bg/info.svg");
                    width: 2.4rem;
                    height: 2.4rem;
                    display: inline-block;
                    position: absolute;
                    left: 2.4rem;
                }
            }
        }
    }

    .Form__MainBody {
        display: block !important;
        .Form__Element {
            margin-bottom: 2.1rem;
        }
    }

    label {
        font-size: $font-size-paragraph-4;
        line-height: $line-height-paragraph-4;
        font-weight: 450;
    }

    input,
    select,
    textarea {
        width: 100%;
        padding: 10px 0;
        border-radius: 0;

        &:focus {
            outline: none;
        }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    select {
        border: none;
        border-bottom: 1px solid $form-input-border;

        &:invalid {
            color: $color-black-300;
        }

        &:focus {
            @include transition(border);
            border-color: $color-black-500;
        }
    }

    input {
        &::placeholder {
            font-weight: 450;
            color: $color-black-300;
        }

        &[type="checkbox"],
        &[type="radio"] {
            width: auto;
        }
    }

    select {
        appearance: none;
        -webkit-appearance: none;
        background: none;
        line-height: 2rem;
    }

    [data-f-type="selection"] {
        position: relative;

        &::after {
            content: '';
            display: block;
            border: .2rem solid;
            width: .8rem;
            height: .8rem;
            position: absolute;
            top: calc(50% + .4rem);
            right: .6rem;
            border-left: none;
            border-top: none;
            transform: rotate(45deg);
        }
    }

    .FormTextbox--Textarea {
        textarea {
            height: 18rem;
            padding: 2rem;
            margin-top: .6rem;
            border: .1rem solid $form-textarea-border;

            &:focus {
                @include transition(border);
                border-color: $color-black-500;
            }

            @include breakpoint(m) {
                max-width: $form-max-width-tablet;
            }

            @include breakpoint(l) {
                max-width: $form-max-width-desktop;
            }

            @include breakpoint(xl) {
                max-width: $form-max-width-desktop-xl;
            }
        }
    }

    .FormFileUpload {
        margin-bottom: 0;

        input {
            padding: 0;
            font-size: 1.2rem;
            color: $color-black-300;

            &[type="file"]::file-selector-button,
            &[type="file"]::-webkit-file-upload-button {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: .6rem 0 1.2rem 0;
                padding: 1.1rem 1.5rem;
                background: none;
                border: .1rem solid $form-input-border;
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 2.6rem;
                text-align: left;
                text-decoration: underline;
                text-underline-offset: .5rem;
                cursor: pointer;

                &:hover {
                    @include transition(color);
                    color: $color-red-500;
                }

                // For Safari cause placeholder text "no file selected" can´t be selected and otherwise button overlays placeholder
                @media not all and (min-resolution:.001dpcm) {
                    @supports (-webkit-appearance: none) {
                        width: auto;
                    }
                }
            }

            &:focus {
                &[type="file"]::file-selector-button {
                    border: .1rem solid $color-black-500;
                }
            }
        }

        @media only screen and (max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
        only screen and (max-device-width: 768px) and (min-resolution: 2dppx) {
            input[type="file"] {
                &::file-selector-button {
                    color: black;
                }
            }
        }
    }

    .FormChoice {
        legend {
            font-size: 1.6rem;
            line-height: 2.8rem;
            font-weight: 450;
        }

        fieldset div {
            position: relative;
            margin-top: 2.4rem;
        }

        input {
            &[type="checkbox"],
            &[type="radio"] {
                position: absolute;
                top: 0;
                opacity: 0;
                width: 2rem;
                height: 2rem;

                + label {
                    &::before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        top: .2rem;
                        left: .2rem;
                        height: 2rem;
                        width: 2rem;
                        border: .1rem solid $color-black-500;
                    }
                }
            }

            &[type="radio"] {
                + label {
                    &::before {
                        border-radius: 50%;
                    }
                }

                &:checked + label {
                    &::before {
                        background-color: $color-black-500;
                        box-shadow: inset 0 0 0 .6rem $color-white;
                    }
                }
            }

            &[type="checkbox"] {
                &:checked + label {
                    &::before {
                        content: '';
                        display: block;
                        background: no-repeat center url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.76805 3.59186L3.78117 5.90857L8.88007 0.590698L10.4463 2.15331L3.89984 8.77943C3.86812 8.81201 3.82582 8.82946 3.78117 8.82946C3.73652 8.82946 3.69305 8.81201 3.6625 8.77943L0.114881 5.15331L1.76805 3.59186Z' fill='%23171717'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        label {
            color: $color-black-500;
            font-size: 1.6rem;
            font-weight: 450;
            line-height: 2.4rem;
            padding-left: 3rem;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .Form_Privacy {
        .m-checkbox-container {
            display: flex;
            position: relative;

            a {
                @include transition(color);
                color: $color-black-500;

                 &:hover {
                    color: $color-red-500;
                 }
            }
        }
    }

    button[type="submit"] {
        width: 100%;
        padding: 1.4rem;
        margin-top: 0;
        background: $color-black-500;
        color: $color-white;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.2rem;

        &:hover {
            @include transition(background);
            background: $color-red-500;
        }
    }

    .ValidationRequired.ValidationFail {
        &,
        legend,
        label {
            color: $color-error-500;
        }

        input,
        textarea,
        select {
            border-color: $color-error-500;
        }

        &.FormSelection::after {
            top: calc(50% - 1rem);
        }
    }

    .Form__Element {
        &__ValidationError {
            font-size: 1.2rem;
            font-weight: 450;
            line-height: 1.8rem;
            margin-top: 1rem;
            color: $color-error-500 !important;
        }
    }

    @include breakpoint(m) {
        max-width: $form-max-width-tablet;
    }

    @include breakpoint(l) {
        max-width: $form-max-width-desktop;
    }

    @include breakpoint(xl) {
        max-width: $form-max-width-desktop-xl;
    }

    &[data-form-type="newsletter"] {
        .Form_Privacy {
            border-top: .1rem solid $form-input-border;
            padding-top: 2.5rem;
        }
    }
}
