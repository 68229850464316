.category-teaser-image {

    h2 {
        @include font-headline-1;
        margin-bottom: 3rem;
    }

    p {
        @include font-paragraph-book-3;
    }

    .button {
        margin-top: 3rem;
        @include breakpoint(l) {
            align-self: start;
        }
    }

    &__image-container {
        position: relative;

        @include breakpoint-property(
                'margin-left',
                (
                    m: -#{$container-padding-m},
                    l: -#{$container-padding-l},
                    xl: -#{$container-padding-xl}
                )
        );

        @include breakpoint-property(
                'padding-bottom',
                (
                    s: 2.5rem,
                    m: 3rem,
                    l: 2.5rem,
                    xl: 3.5rem
                )
        );
    }

    &__teaser-image {
        display: block;
        width: 100%;
        height: auto;

        @include breakpoint-only(s) {
            padding: 3rem 0 0 5rem;
        }

        @include breakpoint-property(
                'width',
                (
                    m: calc(7 * 100% / 8 - 3rem),
                    l: calc(100% - 2.5rem),
                    xl: calc(100% - 3.5rem)
                )
        );

    }

    &__product-image {
        position: absolute;
        width: 100%;
        height: auto;
        filter: $drop-shadow-filter-medium;

        @include breakpoint-only(s) {
            top: 0;
            left: 0;
            max-width: 12.5rem;
        }

        @include breakpoint(m) {
            bottom: 5rem;
            right: 0;
            max-width: 16.5rem;
        }
        @include breakpoint(l) {
            bottom: 0;
            right: -2rem;
        }

        @include breakpoint(xl) {
            bottom: 0;
            right: -3rem;
            max-width: 23rem;
        }

    }

    &__text {

        @include breakpoint-only(m) {
            width: calc(6 * 100% / 8);
            padding-left: 5rem;
        }

        @include breakpoint(l) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding: 0 0 2.5rem 3rem;
        }

        @include breakpoint(xl) {
            padding: 0 0 3.5rem 3rem;
        }
    }

    &--reverse {

        .category-teaser-image {

            &__image-container {
                margin-left: 0;

                @include breakpoint-property(
                        'margin-right',
                        (
                            m: -#{$container-padding-m},
                            l: -#{$container-padding-l},
                            xl: -#{$container-padding-xl}
                        )
                );
            }

            &__teaser-image {
                margin-left: auto;
                @include breakpoint-only(s) {
                    padding: 3rem 5rem 0 0;
                }
            }

            &__product-image {
                @include breakpoint-only(s) {
                    right: 0;
                    left: auto;
                }
                @include breakpoint(m) {
                    left: 0;
                    right: auto;
                }

                @include breakpoint(l) {
                    left: -2rem;
                }

                @include breakpoint(xl) {
                    left: -3rem;
                }
            }

            &__text {
                padding-left: 0;

                @include breakpoint-only(m) {
                    margin-left: auto;
                }

                @include breakpoint(l) {
                    padding: 0 3rem 0 0;
                }
            }
        }
    }
}
