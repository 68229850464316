.page-footer {
    background: $footer-background-color;

    @include breakpoint-property(
        'padding',
        (
            s: 6rem 0 5rem,
            m: 8rem 0 4rem,
            xl: 10rem 0 4rem
        )
    );

    /* Accordion / Cols */
    &__main {
        $accordion-root: &;

        @include breakpoint(m) {
            display: flex;
            flex-wrap: wrap;
        }

        &__menu {
            position: relative;

            @include breakpoint-property(
                'flex',
                (
                    m: 0 0 50%,
                    l: 0 0 25%
                )
            );
        }

        &__close,
        &__title {
            @include breakpoint-only(s) {
                height: 7.4rem;
                width: 100%;
            }
            @include breakpoint(m) {
                pointer-events: none;
            }
        }
        &__close {
            position: absolute;
            top: 0;
            left: 0;
            display: none;
        }

        &__title {
            display: flex;
            align-items: center;
            padding: 2.5rem 0;
            position: relative;
            @include font-headline-5;

            @include breakpoint-only(s) {
                &::before,
                &::after {
                    content: '';
                    display: block;
                    aspect-ratio: 1;
                    position: absolute;
                    background: $color-black-500;
                }
                &::before {
                    top: calc(50% - 1rem);
                    right: 0.9rem;
                    width: 0.2rem;
                    height: 2rem;
                }
                &::after {
                    top: calc(50% - 0.1rem);
                    right: 0;
                    width: 2rem;
                    height: 0.2rem;
                }
            }
        }

        &__content {
            list-style: none;
            font-size: $font-size-headline-5;
            line-height: $line-height-paragraph-5;

            > li {
                margin-bottom: 1.5rem;

                &:last-child {
                    @include breakpoint-only(s) {
                        margin-bottom: 3.5rem;
                    }
                }
            }

            @include breakpoint-only(s) {
                height: 0;
                overflow: hidden;
                border-bottom: 0.1rem solid $footer-divider-border;
            }
        }

        input:checked {
            ~ #{$accordion-root} {
                &__title::before {
                    display: none;
                }

                &__close {
                    display: block;
                }

                &__content {
                    height: auto;
                    border-bottom: 0.2rem solid $footer-divider-border-active;
                }
            }
        }
    }

    /* Logo + Socials */
    &__socials {
        display: flex;
        padding: 5rem 0;
        border-bottom: 0.1rem solid $footer-divider-border;

        @include breakpoint-only(s) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        @include breakpoint(m) {
            flex-direction: row;
            justify-content: space-between;
        }
        @include breakpoint(l) {
            padding: 7rem 0 4rem;
        }

        &__logo {
            width: 10rem;
            height: 5rem;
            display: block;
        }

        &__icons {
            @include breakpoint-only(s) {
                margin: 5rem 0 0;
            }

            @include breakpoint(m) {
                display: flex;
                align-items: center;
            }

            nav {
                display: flex;

                @include breakpoint-only(s) {
                    margin: 1.4rem 0 0;
                }

                a,
                svg {
                    display: block;
                }
                a {
                    margin-left: 3.2rem;

                    @include breakpoint-only(s) {
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    /* Secondary Navigation */
    &__secondary {
        padding: 5rem 0;
        list-style: none;
        @include font-paragraph-book-2;

        @include breakpoint(m) {
            columns: 2;
            column-gap: 0;
            padding: 4rem 0;
        }
        @include breakpoint(l) {
            padding: 4rem 0 7rem;
        }

        > li + li {
            @include breakpoint-property(
                'margin-top',
                (
                    s: 1rem,
                    m: 1.5rem,
                    l: 1.8rem
                )
            );
        }
    }

    /* Copyright */
    &__copyright {
        text-align: center;

        small {
            @include font-paragraph-book-5;
        }
    }
}
