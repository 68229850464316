$fonts-path: '../fonts';

@font-face {
    font-family: 'Circular';
    font-weight: 700;
    font-style: normal;
    font-display: optional;
    src: url('#{$fonts-path}/CircularStd-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular';
    font-weight: 700;
    font-style: italic;
    font-display: optional;
    src: url('#{$fonts-path}/CircularStd-BoldItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular';
    font-weight: 450;
    font-style: normal;
    font-display: optional;
    src: url('#{$fonts-path}/CircularStd-Book.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular';
    font-weight: 450;
    font-style: italic;
    font-display: optional;
    src: url('#{$fonts-path}/CircularStd-BookItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular';
    font-weight: 500;
    font-style: normal;
    font-display: optional;
    src: url('#{$fonts-path}/CircularStd-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Circular';
    font-weight: 500;
    font-style: italic;
    font-display: optional;
    src: url('#{$fonts-path}/CircularStd-MediumItalic.woff2') format('woff2');
}

html {
    font-size: 62.5%;
}

body {
    font-family: $font-family;
    font-weight: 450;
    font-style: normal;
    line-height: 150%;
}

/* Display */
@mixin font-display-1 {
    font-size: $font-size-display-1-mobile;
    line-height: $line-height-display;
    letter-spacing: $letter-spacing-xs;
    font-weight: 500;

    @include breakpoint(m) {
        font-size: $font-size-display-1-tablet;
        letter-spacing: $letter-spacing-s;
    }
    @include breakpoint(l) {
        font-size: $font-size-display-1-desktop;
        letter-spacing: $letter-spacing-s;
    }
}

@mixin font-display-2 {
    font-size: $font-size-display-2-mobile;
    line-height: $line-height-display-2-mobile;
    font-weight: 500;

    @include breakpoint(m) {
        font-size: $font-size-display-2-tablet;
        line-height: $line-height-display;
    }
    @include breakpoint(l) {
        font-size: $font-size-display-2-desktop;
        line-height: $line-height-display;
    }
}

/* Headline */
@mixin font-headline-1 {
    font-size: $font-size-headline-1-mobile;
    line-height: $line-height-headline-1-mobile;
    font-weight: 500;

    @include breakpoint(m) {
        font-size: $font-size-headline-1-tablet;
        line-height: $line-height-headline-1-tablet;
        letter-spacing: $letter-spacing-s;
    }
    @include breakpoint(l) {
        font-size: $font-size-headline-1-desktop;
        line-height: $line-height-headline-1-desktop;
        letter-spacing: $letter-spacing-s;
    }
}

@mixin font-headline-2 {
    font-size: $font-size-headline-2-mobile;
    line-height: $line-height-headline-2;
    font-weight: 500;

    @include breakpoint(m) {
        font-size: $font-size-headline-2-tablet;
    }
    @include breakpoint(l) {
        font-size: $font-size-headline-2-desktop;
        line-height: $line-height-headline-2-desktop;
    }
}

@mixin font-headline-3 {
    font-size: $font-size-headline-3-mobile;
    line-height: $line-height-headline-3;
    font-weight: 500;

    @include breakpoint(m) {
        font-size: $font-size-headline-3-tablet;
    }
    @include breakpoint(l) {
        font-size: $font-size-headline-3-desktop;
    }
}

@mixin font-headline-4 {
    font-size: $font-size-headline-4;
    line-height: $line-height-headline-4;
    font-weight: 500;

    @include breakpoint(m) {
        font-size: $font-size-headline-4;
    }
    @include breakpoint(l) {
        font-size: $font-size-headline-4-desktop;
    }
}

@mixin font-headline-5 {
    font-size: $font-size-headline-5;
    line-height: $line-height-headline-5;
    font-weight: 500;
}

@mixin font-headline-6 {
    font-size: $font-size-headline-6;
    line-height: $line-height-headline-6;
    font-weight: 500;
}

/* Paragraph Book */
@mixin font-paragraph-book-1 {
    font-size: $font-size-paragraph-1;
    line-height: $line-height-paragraph-1;
    font-weight: 450;
}

@mixin font-paragraph-book-2 {
    font-size: $font-size-paragraph-2;
    line-height: $line-height-paragraph-2;
    font-weight: 450;
}

@mixin font-paragraph-book-3 {
    font-size: $font-size-paragraph-3;
    line-height: $line-height-paragraph-3;
    font-weight: 450;
}

@mixin font-paragraph-book-4 {
    font-size: $font-size-paragraph-4;
    line-height: $line-height-paragraph-4;
    font-weight: 450;
}

@mixin font-paragraph-book-5 {
    font-size: $font-size-paragraph-5;
    line-height: $line-height-paragraph-5;
    font-weight: 450;
    letter-spacing: $letter-spacing-l;
}

/* Paragraph Bold */
@mixin font-paragraph-bold-1 {
    font-size: $font-size-paragraph-1;
    line-height: $line-height-paragraph-1;
    font-weight: 700;
}

@mixin font-paragraph-bold-2 {
    font-size: $font-size-paragraph-2;
    line-height: $line-height-paragraph-2;
    font-weight: 700;
}

@mixin font-paragraph-bold-3 {
    font-size: $font-size-paragraph-3;
    line-height: $line-height-paragraph-3;
    font-weight: 700;
}

@mixin font-paragraph-bold-4 {
    font-size: $font-size-paragraph-4;
    line-height: $line-height-paragraph-4;
    font-weight: 700;
}

@mixin font-paragraph-bold-5 {
    font-size: $font-size-paragraph-5;
    line-height: $line-height-paragraph-5;
    font-weight: 700;
    letter-spacing: $letter-spacing-l;
}

.text {
    &--display-1 {
        @include font-display-1;
    }

    &--display-2 {
        @include font-display-2;
    }

    &--headline-1 {
        @include font-headline-1;
    }

    &--headline-2 {
        @include font-headline-2;
    }

    &--headline-3 {
        @include font-headline-3;
    }

    &--headline-4 {
        @include font-headline-4;
    }

    &--headline-5 {
        @include font-headline-5;
    }

    &--headline-6 {
        @include font-headline-6;
    }

    &--paragraph-book-1 {
        @include font-paragraph-book-1;
    }

    &--paragraph-book-2 {
        @include font-paragraph-book-2;
    }

    &--paragraph-book-3 {
        @include font-paragraph-book-3;
    }

    &--paragraph-book-4 {
        @include font-paragraph-book-4;
    }

    &--paragraph-book-5 {
        @include font-paragraph-book-5;
    }

    &--paragraph-bold-1 {
        @include font-paragraph-bold-1;
    }

    &--paragraph-bold-2 {
        @include font-paragraph-bold-2;
    }

    &--paragraph-bold-3 {
        @include font-paragraph-bold-3;
    }

    &--paragraph-bold-4 {
        @include font-paragraph-bold-4;
    }

    &--paragraph-bold-5 {
        @include font-paragraph-bold-5;
    }

    .link {
        font-size: inherit;
    }

    &--link-combination {
        display: block;

        @include breakpoint(m) {
            display: flex;
            justify-content: space-between;
        }

        h1 {
            @include breakpoint-property(
                    'max-width',
                    (
                        m: 6 * 100% / 8,
                        l: 9 * 100% / 12
                    )
            );
            @include breakpoint-property(
                'margin-right',
                (
                    m: $container-padding-m,
                    l: $container-padding-l,
                    xl: $container-padding-xl
                )
            );
        }

        .link {
            margin-top: 1.5rem;
            display: inline-block;

            @include breakpoint(m) {
                margin: 0;
                align-self: flex-start;
                text-align: right;
            }

            @include breakpoint-property(
                    'min-width',
                    (
                        m: 2 * 100% / 8,
                        l: 3 * 100% / 12
                    )
            );
        }
    }

    &--two-columns {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include breakpoint(m) {
            flex-direction: row;
        }

        .column {
            @include breakpoint(m) {
                width: 50%;
            }
            > :last-child {
                margin-bottom: 0;
            }
        }

        .column:not(:last-child) {
           padding-bottom: 3rem;

            @include breakpoint(m) {
                padding-right: 2rem;
                padding-bottom: 0;
            }
        }
    }

    &--combination-vertical {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 2rem;

            @include breakpoint(m) {
                margin-bottom: 2.5rem;
            }
        }

        p:not(:last-child) {
            margin-bottom: 3rem;
        }
    }

    &--combination-horizontal {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include breakpoint-only(m) {
                width: 7 * 100% / 8;
                margin-left: 5.5rem;
            }
        }

        p:not(:last-child) {
            margin-top: 2rem;

            @include breakpoint(l) {
                margin-top: 3rem;
            }

            @include breakpoint-only(m) {
                width: 6 * 100% / 8;
                margin-left: auto;
            }

            @include breakpoint-only(s) {
                margin-left: 5rem;
            }
        }
    }
}
