$slider-counter-height: 1.8rem;

.slider {
    width: 100%;
    position: relative;
    visibility: hidden;

    container-name: slider-root;
    container-type: inline-size;

    &[data-is-loaded] {
        visibility: visible;
    }

    @at-root .grid__container {
        > .slider,
        [class*="spacer-"] > .slider {
            @include breakpoint-only(s) {
                left: -#{$container-padding-s};
                right: -#{$container-padding-s};
                width: calc(100% + #{$container-padding-s * 2});
            }

            @include breakpoint-only(m) {
                left: -#{$container-padding-m};
                right: -#{$container-padding-m};
                width: calc(100% + #{$container-padding-m * 2});
            }
        }
    }

    &__wrapper {
        overflow: hidden;
        position: relative;
        z-index: 1;
        width: 100%;
        padding-bottom: $slider-counter-height;
    }

    &__slides {
        display: flex;
        flex-direction: row;
        list-style: none;
        position: relative;
        width: 10000000px;
        will-change: left;
        touch-action: pan-x;
        .editor-content & {
            list-style: none;
        }
        &.shifting {
            @include transition(left);
        }

        figure {
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: $color-white;

            > div {
                display: flex;
                justify-content: center;
                background: $slider-background-color;
                flex-grow: 1;

                @include breakpoint-only(s) {
                    max-height: 100vw;
                    aspect-ratio: 1/1;
                }

                @include breakpoint(m) {
                    aspect-ratio: 16/9;
                }
            }
        }
    }

    &__slide {
        user-select: none;
        -webkit-touch-callout: none;
        list-style: none;
        .editor-content & {
            margin-top: 0;
        }
        @include breakpoint(l) {
            cursor: default;
        }

        picture {
            display: flex;
            justify-content: center;
            aspect-ratio: 1/1;
            @include breakpoint(m) {
                aspect-ratio: 16/9;
            }
        }

        img {
            display: block;
            align-self: center;

            &[data-format] {
                max-width: 100%;
                max-height: 100%;
            }

            &[data-format="landscape"] {
                width: auto;
                height: auto;
            }
            &[data-format="portrait"] {
                width: auto;
                height: 100%;
            }
            &[data-format="square"] {
                width: 100%;
                height: 100%;
                aspect-ratio: 1/1;

                @include breakpoint(m) {
                    width: auto;
                }
            }
        }

        &__figcaption {
            @include font-paragraph-book-5;
            display: flex;
            justify-content: center;
            text-align: center;
            padding: 1.5rem 2.5rem;
            color: $color-black-400;
        }
    }

    &__control {
        @include transition(color, background-color);
        position: absolute;
        width: 4.4rem;
        height: 4.4rem;
        background: $color-white;
        z-index: 2;
        margin-top: -#{$slider-counter-height / 2};
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        cursor: pointer;

        &--prev,
        &--next {
            &::after {
                display: block;
                content: '';
                border: .2rem solid;
                width: 1rem;
                height: 1rem;
                position: absolute;
                top: calc(50% - 0.5rem);
                border-left: none;
                border-top: none;
                transform: rotate(-45deg);
            }
        }

        &--prev {
            left: 0;

            @include breakpoint-only(m) {
                left: 5rem;
            }

            @container slider-root (width < 768px) {
                left: 0;
            }

            &::after {
                transform: rotate(135deg);
                margin-left: .2rem;
            }
        }
        &--next {
            right: 0;

            @include breakpoint-only(m) {
                right: 5rem;
            }

            @container slider-root (width < 768px) {
                right: 0;
            }

            &::after {
                margin-right: .2rem;
            }
        }

        @include breakpoint(l) {

            &:hover {
                background: $color-red-500;
                color: $color-white;
            }
        }
    }

    &__counter {
        height: $slider-counter-height;
        bottom: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        color: $color-black-500;
        @include font-paragraph-book-5;
    }

    &--one-image {
        display: flex;
        justify-content: center;
        max-height: 100vw;
        width: 100vw;
        @include breakpoint(m) {
            aspect-ratio: 16/9;
            width: 100%;
            max-height: unset;
        }
        .img {
            object-fit: contain;
        }
    }
}
