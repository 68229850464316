.breadcrumb {
    list-style: none;
    display: flex;
    height: 3.8rem;
    margin-bottom: 2rem;

    @include breakpoint-only(s) {
        white-space: nowrap;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    li {
        @include font-paragraph-book-5;
        display: flex;
        align-items: center;

        a,
        .icon {
            display: block;
            color: $color-cool-grey-400;
        }

        a {
            text-decoration: none;
            border-bottom: .1rem solid transparent;
            @include transition(border-bottom);

            &:hover {
                border-color: inherit;
            }
        }

        .icon {
            margin: 0.1rem 1.4rem 0;
            width: 0.42rem;
            height: 0.68rem;
        }
    }
}
