*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

a,
body,
button,
input,
select,
textarea {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    -webkit-text-size-adjust: none;
    touch-action: manipulation;
}

input,
button,
textarea,
select {
    font: inherit;
    color: inherit;
}

input {
    border-radius: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

button {
    cursor: pointer;
}
