#stopConfettiButton {
    display: none;
    position: fixed;
    bottom: 30px;
    left: 30px;
}
@media (prefers-reduced-motion) {
    #stopConfettiButton,
    #tsparticles {
        display: none !important;
    }
}

.confetti {
    &__text {
        color: $color-red-500;
        font-size: 40px;
        font-weight: 700;
        position: absolute;
        z-index: 1000;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        animation: confettiAnimation 15s ease-in-out forwards;
        text-align: center;
        display: none;
        text-shadow: 1px 1px 1px #e4e3e3, 1px 2px 1px #ffb9b954, 1px 3px 1px #dfdfdf, 1px 4px 1px #bcbcbc, 1px 5px 1px #b8b8b8, 1px 6px 1px #aaaaaa, 1px 7px 1px #4b4b4b, 2px 7px 6px rgba(16, 16, 16, 0.4), 0px 13px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2), 1px 14px 60px rgba(16, 16, 16, 0.2);
        }
}
@keyframes confettiAnimation {
    0% {
        top: 5%;
        opacity: 0;
        transform: translateX(-50%) scale(0.5);
        letter-spacing: -2px;
        line-height: 45px;
        filter: blur(2px);
    }
    40% {
        opacity: 1;
        transform: translateX(-50%) scale(1.2);
        letter-spacing: normal;
        filter: blur(0px);
    }
    80% {
        opacity: 1;
        transform: translateX(-50%) scale(1.2);
        letter-spacing: normal;
        filter: blur(0px);
    }
    100% {
        top: 50%;
        opacity: 0;
        transform: translateX(-50%) scale(1.2);
        letter-spacing: normal;
        filter: blur(0px);
    }
}

@media (max-width: 768px) {
    .confetti__text {
        font-size: 32px; /* Reduce font size for smaller screens */
        line-height: 38px; /* Ensure enough space between lines */
        max-width: 90%; /* Avoid overflowing */
        word-break: break-word;
        white-space: pre-line;
    }

    @keyframes confettiAnimation {
        0% {
            top: 5%;
            opacity: 0;
            transform: translateX(-50%) scale(0.5);
            letter-spacing: -2px;
            line-height: 42px;
            filter: blur(2px);
        }
        40% {
            opacity: 1;
            transform: translateX(-50%) scale(1.1);
            letter-spacing: normal;
            line-height: 42px;
            filter: blur(0px);
        }
        80% {
            opacity: 1;
            transform: translateX(-50%) scale(1.1);
            letter-spacing: normal;
            line-height: 42px;
            filter: blur(0px);
        }
        100% {
            top: 50%;
            opacity: 0;
            transform: translateX(-50%) scale(1.1);
            letter-spacing: normal;
            line-height: 36px; /* Reduce spacing slightly at the end */
            filter: blur(1px);
        }
    }
}
