.offcanvas__navigation {
    @include transition(transform);
    padding: 0 $offcanvas-padding;
    list-style: none;
    will-change: transform;
    transform: translate3d(0, 0, 0);
    background-color: $color-cool-grey-100;

    &.is-active {
        transform: translate3d(-100%, 0, 0);
    }

    a {
        display: block;
        text-decoration: none;
    }

    &--lvl1,
    &--lvl2 {
        padding-top: 1.5rem;

        button.is-active ~ .offcanvas__navigation {
            display: block;
        }
    }

    &--lvl2 {
        top: 0;
        left: 100%;
        width: 100%;
        position: absolute;
        display: none;
        min-height: calc(100vh - #{$offcanvas-header-height});

        li:last-child {
            padding-bottom: 3rem;
        }
    }

    &--lvl3 {
        display: none;
        padding: 0;
        margin-bottom: -0.1rem;
        border-top: .1rem solid $color-black-500;

        li:last-child {
            margin: 0 -2.5rem;
            padding: 3rem 2.5rem;
            border-bottom: .1rem solid $offcanvas-divider-border;
        }
    }

    a:not(.button),
    button {
        width: 100%;
        font-size: $font-size-headline-4;
        line-height: $line-height-headline-4;
        font-weight: 500;
        color: $color-black-500;
        padding: 2.5rem 0;
        text-align: left;
        border-bottom: .1rem solid $offcanvas-divider-border;
    }

    button {
        position: relative;

        &::after {
            content: '';
            display: block;
            border: .2rem solid;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: calc(50% - 0.5rem);
            right: .6rem;
            border-left: none;
            border-top: none;
            transform: rotate(-45deg);
        }

        &.is-active {
            &::after {
                right: .3rem;
                transform: rotate(45deg);
            }
        }
    }

    &--lvl3 {
        a:not(.button) {
            @include font-paragraph-book-1;
            @include transition(color);
            padding-left: 2.5rem;

            &:hover {
                color: $color-red-500;
            }
        }
    }
}

.offcanvas__lang {
    display: flex;
    padding: 4rem $offcanvas-padding $offcanvas-padding;

    @media (min-height: 785px) {
        bottom: 0;
        position: fixed;
        padding: $offcanvas-padding;
        z-index: 0;
        background-color: $color-cool-grey-100;
        width: 100%;
    }

    a {
        display: block;
        line-height: 155%;
        font-size: 1.6rem;
        font-weight: $font-weight-regular;
        color: $color-black-400;
        text-decoration: none;

        &.is-active {
            color: $color-black-500;
            border-bottom: .1rem solid;
        }

        + a {
            margin-left: 3rem;
        }
    }
}
