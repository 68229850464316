.download-teasers {
    display: flex;
    flex-wrap: wrap;
    margin-top: -4rem;
    @include breakpoint(xl) {
        margin-top: -5rem;
    }
    @include breakpoint-property(
        'margin-right',
        (
            s: -2rem,
            m: -4rem,
            l: -5rem,
            xl: -5rem
        )
    );

    .downloadteaserblock {
        margin-top: 4rem;
        margin-bottom: 0 !important;

        @include breakpoint-property(
            'width',
            (
                s: 50%,
                m: 25%,
                l: 25%,
                xl: 20%
            )
        );
        @include breakpoint-property(
            'padding-right',
            (
                s: 4rem,
                m: 4rem,
                l: 5rem,
                xl: 5rem
            )
        );
        @include breakpoint(xl) {
            margin-top: 5rem;
        }
    }
}


.download-teaser {
    &__image-wrapper {
        max-width: 85%;
    }
    &__image {
        filter: $drop-shadow-filter-small;
        width: 100%;
        height: auto;
        &--fallback {
            width: 85%;
        }
    }

    &__content {
        width: 100%;
    }

    &__link {
        color: $color-black-500;
        @include transition(color, border-bottom);

        &:hover {
            color: $color-red-500;
            text-decoration: none;
        }

        &:focus,
        &:active {
            color: $color-cool-grey-500;
            text-decoration: none;
        }
    }

    &__title {
        @include font-paragraph-book-4;
        margin-top: 1.5rem;
        margin-bottom: 0;
        @include line-clamp(3);
    }

    &__size {
        @include font-paragraph-book-4;
        margin-bottom: 0;
    }


    &__cta {
        display: inline-block;
        @include font-headline-6;
        border-bottom: 0.2rem solid;
        margin-top: 1.15rem;
        max-width: 100%;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__cta-icon {
        margin-right: 0.4rem;
    }
}

