/*
    Grid mixins
*/

@mixin breakpoint( $breakpoint-name ) {
    @include srgrid-content-breakpoint( $breakpoint-name ) {
        @content;
    }
}

@mixin breakpoint-only( $breakpoint-name ) {
    @include srgrid-content-breakpoint-only( $breakpoint-name ) {
        @content;
    }
}

@mixin breakpoint-property( $property, $property-values ) {
    $breakpoints: map-keys($property-values);
    $values: map-values($property-values);

    @for $index from 1 through length($breakpoints) {
        $breakpoint: nth($breakpoints, $index);
        $value: nth($values, $index);

        @include breakpoint( $breakpoint ) {
            #{$property}: $value;
        }
    }
}

/*
    Default breakpoints
*/

$breakpoint-s: 0;
$breakpoint-m: 768px;
$breakpoint-l: 1024px;
$breakpoint-xl: 1440px;
$breakpoint-xxl: 1920px;

$grid-breakpoints: (
    s: $breakpoint-s,
    m: $breakpoint-m,
    l: $breakpoint-l,
    xl: $breakpoint-xl,
    xxl: $breakpoint-xxl
);

/*
    Max width grid container
*/

$grid-container-max-width: 1600px;

/*
    Container paddings
*/

$container-padding-s: 24px;
$container-padding-m: 51px;
$container-padding-l: 65px;
$container-padding-xl: 135px;
$container-padding-xxl: 135px;

$grid-gutter-widths: 30px;


/*
    sR Grid variables
*/

$srgrid-column-count: 12;
$srgrid-breakpoints: $grid-breakpoints;
$srgrid-gutter-widths: $grid-gutter-widths;
$srgrid-parent-class: 'grid';
$srgrid-show-grid-class: 'grid-current-breakpoint';

/*
    Create sR Grid
*/

@include srgrid-create-grid(
    $grid-debug-feature: false,

    $grid-fluid-feature: true,
    $grid-fluid-responsive-feature: true,
    $grid-fluid-only-feature: true,

    $grid-container-feature: true,

    $grid-offset-feature: true,
    $grid-offset-responsive-feature: true,
    $grid-offset-only-feature: true,

    $grid-hide-feature: true,
    $grid-hide-responsive-feature: true,
    $grid-hide-only-feature: true,

    $grid-distribution-feature: true,
    $grid-distribution-responsive-feature: true,
    $grid-distribution-only-feature: true,

    $grid-reverse-feature: false,
    $grid-reverse-responsive-feature: true,
    $grid-reverse-only-feature: false,

    $grid-reorder-feature: false,
    $grid-reorder-responsive-feature: true,
    $grid-reorder-only-feature: false,

    $grid-alignment-feature: true,
    $grid-alignment-responsive-feature: false,
    $grid-alignment-only-feature: false,

    $grid-align-column-feature: true,
    $grid-align-column-responsive-feature: true,
    $grid-align-column-only-feature: true,

    $grid-block-feature: false,
    $grid-block-responsive-feature: false,
    $grid-block-only-feature: false,

    $grid-gapless-feature: true,
    $grid-gapless-responsive-feature: false,
    $grid-gapless-only-feature: false,

    $grid-column-fractions-feature: false,
    $grid-column-fractions-responsive-feature: false,
    $grid-column-fractions-only-feature: false
);


/*
    Handle grid items
*/

@include breakpoint-only(s) {
    @for $i from 1 through 2 {
        .grid__col_#{$i}_s {
            flex-basis: (100% / 2 * $i);
            max-width: (100% / 2 * $i);
        }
    }
}

@include breakpoint-only(m) {
    @for $i from 1 through 8 {
        .grid__col_#{$i}_m {
            flex-basis: (100% / 8 * $i);
            max-width: (100% / 8 * $i);
        }
    }
}


/*
    Grid container styles
*/

.grid__container {
    margin: 0 auto;
    padding: 0 $container-padding-s;

    @include breakpoint-property(
        'max-width',
        (
            l: $grid-container-max-width
        )
    );

    @include breakpoint-property(
        'padding',
        (
            s: 0 #{$container-padding-s},
            m: 0 #{$container-padding-m},
            l: 0 #{$container-padding-l},
            xl: 0 #{$container-padding-xl},
            xxl: 0 #{$container-padding-xxl}
        )
    );

    &_xl {
        margin: 0 auto;

        @include breakpoint-property(
            'max-width',
            (
                l: $breakpoint-xl
            )
        );
    }
}


/*
    Hide classes
*/

@each $breakpoint in map-keys($grid-breakpoints) {
    @include breakpoint( $breakpoint ) {
        .hide-#{$breakpoint} {
            display: none !important;
        }
    }

    @include breakpoint-only( $breakpoint ) {
        .hide-#{$breakpoint}-only {
            display: none !important;
        }
    }
}
