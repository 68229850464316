.editor-content {
    h1 {
        @include font-headline-1;
        margin-bottom: 3.5rem;
    }
    h2 {
        @include font-headline-2;
        margin-bottom: 3.5rem;
    }
    h3 {
        @include font-headline-3;
        margin-bottom: 2.5rem;
    }
    h4 {
        @include font-headline-4;
        margin-bottom: 2.5rem;
    }
    h5 {
        @include font-headline-5;
        margin-bottom: 2.5rem;
    }
    h6 {
        @include font-headline-6;
        margin-bottom: 2.5rem;
    }
    p,
    ol,
    ul,
    dl,
    dt,
    dd,
    blockquote,
    address,
    table {
        &:not([class]) {
            @include font-paragraph-book-3;
            margin-bottom: 2.5rem;
        }
    }
    p {
        &.text--display-1,
        &.text--display-2,
        &.text--headline-1,
        &.text--headline-2 {
            margin-bottom: 3.5rem;
        }
        &.text--headline-3,
        &.text--headline-4,
        &.text--headline-5,
        &.text--headline-6,
        &.text--paragraph-book-1,
        &.text--paragraph-book-2,
        &.text--paragraph-book-3,
        &.text--paragraph-book-4,
        &.text--paragraph-book-5,
        &.text--paragraph-bold-1,
        &.text--paragraph-bold-2,
        &.text--paragraph-bold-3,
        &.text--paragraph-bold-4,
        &.text--paragraph-bold-5 {
            margin-bottom: 2.5rem;
        }
    }
    pre {
        font-size: $font-size-paragraph-3;
        line-height: $line-height-paragraph-3;
        font-weight: 450;
        margin-bottom: 2.5rem;
    }

    th {
        @include font-paragraph-book-3;
        padding: 0.2rem 0.8rem;
        @include font-paragraph-bold-3;
    }
    td {
        @include font-paragraph-book-3;
        padding: 0.2rem 0.8rem;
    }
    ol {
        padding: revert;
        list-style: decimal;
    }
    ul {
        padding: revert;
        list-style: disc;
    }
    li {
        margin-top: 1rem;
    }

    a:not([class]) {
        color: $color-black-500;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-thickness: 0.2rem;
        text-underline-offset: 0.5rem;
        text-decoration-color: inherit;
        @include transition(color, border-bottom);

        &:hover {
            color: $color-red-500;
            text-decoration-color: $color-red-500;
        }

        &:focus,
        &:active {
            color: $color-cool-grey-500;
            text-decoration-color: $color-cool-grey-500;
        }

        &[disabled],
        &.disabled {
            color: $color-black-300;
            text-decoration-color: $color-black-300;
            pointer-events: none;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    dt,
    dd,
    blockquote,
    address {
        a {
            cursor: pointer;
            text-decoration: underline;
            text-decoration-thickness: 0.2rem;
            text-underline-offset: 0.7rem;
            text-decoration-color: inherit;
            @include transition(color, border-bottom);
        }
    }

    button:not([class]) {
        font-weight: $font-weight-medium;
        text-decoration: none;
        margin-right: 2rem;
        border: none;
        text-align: center;
        transition: 250ms background-color, 250ms color, 250ms border-color;
        letter-spacing: $letter-spacing-m;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $color-white;
        background: $color-black-500;
        padding: 1rem 1.8rem;
        margin-bottom: 0.5rem;

        &:hover {
            background-color: $color-red-500;
        }
        &:focus,
        &:active {
            background-color: $color-cool-grey-500;
        }

        &[disabled],
        &.disabled {
            background-color: $color-black-300;
            pointer-events: none;
        }
    }
    hr {
        border-bottom: 1px solid $color-black-500;
        margin: 2.5rem 0;
    }


}
