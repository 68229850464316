.product-tile-simple {
    margin: 0;
    @include breakpoint-property(
        'margin-top',
        (
            s: 4rem,
            m: 5rem,
            l: 7rem
        )
    );

    @include breakpoint-property(
        'width',
        (
            s: 50%,
            m: 25%,
            l: 20%
        )
    );

    @include breakpoint-property(
        'padding-right',
        (
            s: 3rem,
            l: 5rem
        )
    );
    
    &__tiles-wrapper {
        display: flex;
        flex-wrap: wrap;
        @include breakpoint-property(
            'margin-top',
            (
                s: -4rem,
                m: -5rem,
                l: -7rem
            )
        );
        @include breakpoint-property(
            'margin-bottom',
            (
                s: 4rem,
                m: 5rem,
                l: 7rem
            )
        );
    }

    @include breakpoint(l) {
        display: block;
    }
    &__image-wrapper {
        width: 100%;
        margin-top: 0;
        margin-bottom: 3rem;
        text-align: left;
    }
    &__image {
        filter: $drop-shadow-filter-small;
        height: auto;
        max-width: 85%;
    }

    &__title {
        @include font-headline-6;
        color: $color-black-500;
        @include line-clamp(3);
        padding-bottom: 2px;
        .link {
            text-underline-offset: 0.3rem;
        }
    }
}
