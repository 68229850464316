.story-teaser-history {
    position: relative;
    @include breakpoint-property(
        'padding-top',
        (
            s: 5rem,
            l: 7rem
        )
    );
    @include breakpoint-property(
        'padding-bottom',
        (
            s: 1rem,
            m: 3rem,
            l: 3rem
        )
    );
    &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -50vw;
        width: 100vw;
        background-color: $app-background-color;
        z-index: -1;
        
    }

    &--two-cols {
    @include breakpoint-property(
        'margin-top',
            (
                s: -8rem,
                m: 0
            )
        );
    }
}
