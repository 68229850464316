.page-header {
    position: fixed;
    top: 0;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: row;
    background: $color-white;
    border-bottom: .1rem solid rgba($color-cool-grey-500, .25);
    height: $header-height-mobile;
    z-index: $header-z-index;

    @include breakpoint(m) {
        height: $header-height-tablet;
    }

    @include breakpoint(xl) {
        height: $header-height-desktop;
    }

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__logo {
        height: auto;

        @include breakpoint(s) {
            width: 7.9rem;
        }
        @include breakpoint(m) {
            width: 10.8rem;
        }
        @include breakpoint(xl) {
            width: 12.0rem;
        }
    }

    .select-wrapper {
        position: relative;
        margin: 0 4.5rem;

        select {
            @include transition(color, border-color);
            appearance: none;
            -webkit-appearance: none;
            width: 3rem;
            border-radius: 0;
            cursor: pointer;
            background-color: transparent;
            font-size: 1.4rem;
            line-height: 150%;
            font-weight: $font-weight-regular;

            option {
                color: $color-black-500;
            }
        }

        &::after {
            @include transition(color);
            content: '\25BC';
            position: absolute;
            top: .3rem;
            right: -.2rem;
            pointer-events: none;
        }

        &:hover {
            border-bottom: 0.2rem solid $color-red-500;

            select,
            &::after {
                padding-top: .2rem;
                color: $color-red-500;
            }
        }
    }


    .navigation__wrapper,
    .navigation,
    .meta-navigation {
        &,
        &__item {
            display: flex;
            align-items: center;
        }
    }

    .navigation,
    .meta-navigation {

        &__item {
            flex-direction: column;
            background: none;
            text-decoration: none;
        }
    }

    .navigation {
        display: none;

        &__item:not(:last-child) {
            margin-right: 2.4rem;
        }

        @include breakpoint(xl) {
            display: flex;
        }

        &__item {
            @include transition(color);
            line-height: 3.2rem;
            font-size: 1.7rem;
            border-bottom: 0.2rem solid transparent;

            &:hover {
                color: $color-red-500;
                border-bottom: 0.2rem solid $color-red-500;
            }

            &.is-active {
                border-bottom: 0.2rem solid $color-black-500;

                &:hover {
                    color: $color-black-500;
                }
            }
        }

        a {
            color: $color-black-500;
        }
    }

    .meta-navigation {

        &__item {
            font-size: 1.0rem;

            &:not(:last-child) {
                margin-right: 1.8rem;
            }

            &:last-child {
                margin-left: 1.8rem;
            }

            span,
            .icon {
                @include transition(color, border-color);
                color: $color-black-500;
            }

            span {
                padding-top: .4rem;
                line-height: 2rem;
                color: $color-black-400;
                border-bottom: 0.2rem solid transparent;
            }

            @include breakpoint(m) {
                font-size: 1.4rem;

                &:not(:last-child) {
                    margin-right: 2.2rem;
                }

                &:last-child {
                    margin-left: 2.5rem;
                }
            }

            @include breakpoint(l) {

                &:nth-last-child(2) {
                    margin: 0;
                }

                &:hover {
                    span,
                    .icon {
                        color: $color-red-500;
                    }

                    span {
                        border-bottom: 0.2rem solid $color-red-500;
                    }
                }
            }
        }
    }

    > .grid__container {
        flex: 1;
        @media (min-width:1440px) and (max-width:1599px) {
            padding: 0 6.5rem;
        }
    }
}
