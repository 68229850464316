.offcanvas__login {
    @include font-paragraph-book-3;
    padding: 3rem $offcanvas-padding 0;

    @media (min-height: 785px) {
        padding: 3rem $offcanvas-padding 10rem $offcanvas-padding;
    }

    &__title {
        @include font-headline-3;
        padding-bottom: 2rem;
        display: block;

        @include breakpoint(l) {
            padding-bottom: 2.5rem;
        }
    }

    div.button-link-block {
        display: block;
    }

    .editor-content + .button-link-block {
        margin-top: 3rem;
    }

    .button {
        font-size: $font-size-paragraph-3;
        font-weight: $font-weight-regular;
        display: block;
        width: 100%;
    }
    .editor-content {
        p {
            margin-bottom: 0;
        }
        a {
            color: $color-black-500;
            cursor: pointer;
            text-decoration: underline;
            text-decoration-thickness: 0.2rem;
            text-underline-offset: 0.7rem;
            font-size: $font-size-link-small;
            text-decoration-color: currentColor;
            @include transition(color, text-decoration);

            &:hover {
                color: $color-red-500;
                text-decoration-color: $color-red-500;
                border-bottom: none;
            }

            &:focus,
            &:active {
                color: $color-cool-grey-500;
                text-decoration-color: $color-cool-grey-500;
                border-bottom: none;
            }
            
        }
    }
}
