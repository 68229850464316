$sources: catalog, website, downloads;

.offcanvas__search {
    $search-root: &;
    @include font-paragraph-book-3;
    padding: $offcanvas-padding $offcanvas-padding 0;

    &__field {
        width: 100%;
        border-bottom: .1rem solid $color-black-500;
        background: none;
        color: $color-black-500;
        font-size: $font-size-headline-4-desktop;
        line-height: $line-height-headline-4;
        height: 3.7rem;
        outline: none;
        -webkit-appearance: none;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }

        &::placeholder {
            color: rgba($color-black-500, .25);
        }

        &:invalid {
            ~ #{$search-root}__results::before {
                display: block;
                text-align: center;
                content: attr(data-i18n-warning-empty-search);
            }
        }
        &:not(:placeholder-shown) {
            ~ #{$search-root}__results[data-status="request"]::before {
                display: block;
                content: '';
                width: 3.2rem;
                height: 3.2rem;
                margin: 5rem auto;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32'%3E%3Cg%3E%3Cg class='nc-loop-squares-anim-2-icon-f'%3E%3Crect width='14' height='14' x='17' y='1' fill='%23444' data-color='color-2' rx='1'/%3E%3Crect width='14' height='14' x='17' y='17' fill='%23444' rx='1'/%3E%3Crect width='14' height='14' x='1' y='17' fill='%23444' data-color='color-2' rx='1'/%3E%3Crect width='14' height='14' x='1' y='1' fill='%23444' rx='1'/%3E%3C/g%3E%3Cstyle%3E%3C!%5BCDATA%5B@keyframes nc-loop-squares-anim-2%7B0%25,to%7Bopacity:1%7D50%25%7Bopacity:0%7D%7D.nc-loop-squares-anim-2-icon-f%3E*%7B--animation-duration:2s;animation:nc-loop-squares-anim-2 var(--animation-duration) infinite%7D.nc-loop-squares-anim-2-icon-f%3E:nth-child(1)%7Bopacity:0;animation-delay:calc(-1*var(--animation-duration)/2)%7D.nc-loop-squares-anim-2-icon-f%3E:nth-child(2)%7Bopacity:.5;animation-delay:calc(-1*var(--animation-duration)/4)%7D.nc-loop-squares-anim-2-icon-f%3E:nth-child(4)%7Banimation-delay:calc(var(--animation-duration)/4)%7D%5D%5D%3E%3C/style%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 3.2rem 3.2rem;
            }
            ~ #{$search-root}__results[data-status="no-results"] ~ #{$search-root}__no-results {
                display: flex !important;
            }
            ~ #{$search-root}__reset {
                display: block !important;
            }
        }

        ~ #{$search-root}__reset {
            right: $offcanvas-padding;
            position: absolute;
            width: 2rem;
            height: 2rem;
            margin-top: -2.8rem;
            display: none;

            &::before,
            &::after {
                position: absolute;
                top: .2rem;
                content: '';
                height: 1.5rem;
                width: .1rem;
                background-color: $color-black-500;
            }
            &::before {
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
    }

    &__tabs,
    &__best-bets {
        margin-top: 3rem;
    }

    &__results {
        margin-top: 3rem;

        &[data-status="results"] {
            margin-top: 1.5rem;

            &::before {
                @include font-paragraph-book-5;
                color: rgba($color-black-500, .5);
                margin-right: .4rem;
                display: inline-block;
                content: attr(data-i18n-total-results);
            }

            &[data-source="catalog"] {
                ~ #{$search-root}__catalog-button {
                    display: block !important;
                }
            }
        }

        &[data-status="no-results"] {
            @each $source in $sources {
                &[data-source="#{$source}"] {
                    ~ #{$search-root}__no-results {
                        button[data-source="#{$source}"] {
                            display: none;
                        }
                    }
                }
            }
        }

        small {
            @include font-paragraph-book-5;
            color: rgba($color-black-500, .5);
        }
        span {
            @include font-paragraph-book-3;
            color: $color-black-500;
        }
        nav {
            list-style: none;
            margin-top: 1.5rem;
            border-top: .1rem solid rgba($color-black-500, .04);
        }
        a {
            display: flex;
            padding: 1.5rem 0;
            border-bottom: .1rem solid rgba($color-black-500, .04);
            text-decoration: none;

            @include breakpoint(l) {
                padding: 2rem 0;
            }

            small,
            span {
                @include line-clamp(3);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            @media (hover: hover) {
                &:hover {
                    span {
                        @include transition(color);
                        color: $color-red-500;
                        text-decoration: underline;
                    }
                }
            }
        }

        figure {
            width: 3rem;
            min-width: 3rem;
            margin-right: 1.5rem;

            @include breakpoint(l) {
                width: 5rem;
                min-width: 5rem;
                margin-right: 2rem;
            }

            img {
                display: block;
                width: 3rem;
                height: 3rem;

                @include breakpoint(l) {
                    width: 5rem;
                    height: 5rem;
                }
            }

            [data-format="download"] {
                font-size: 1rem;
                font-weight: 700;
                width: 3rem;
                height: 4rem;
                border: .2rem solid $color-black-500;
                color: $color-black-500;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                @include breakpoint(l) {
                    width: 5rem;
                    height: 6.7rem;
                }

                &::before {
                    content: '';
                    top: -0.2rem;
                    right: -0.2rem;
                    width: .8rem;
                    height: .8rem;
                    position: absolute;
                    background-color: $color-cool-grey-100;
                }

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -0.1rem;
                    right: -0.1rem;
                    width: 0;
                    height: 0;
                    border-right: .5rem solid transparent;
                    border-bottom: .5rem solid $color-black-500;
                }
            }

            [data-format="category"],
            [data-format="page"],
            [data-format="fallback"] {
                color: $color-black-500;
                font-size: 1.15rem;
                font-weight: 500;
                writing-mode: vertical-rl;
                transform: rotate(180deg);
            }

            &.type-category,
            &.type-page,
            &.show-fallback {
                border-left: 2px solid $color-black-500;
                min-height: 5rem;
            }
        }
    }

    &__tabs {
        display: flex;
        justify-content: space-between;
        list-style: none;
        border-bottom: .1rem solid $offcanvas-divider-border;

        label {
            @include font-paragraph-book-4;
            line-height: 2.8rem;
            color: rgba($color-black-500, .25);
            display: block;
            position: relative;
            margin-bottom: -0.1rem;
            cursor: pointer;
            @include transition(color);

            @media (hover: hover) {
                &:hover {
                    color: $color-black-500;
                }
            }
        }

        input {
            display: none;

            &:checked ~ label {
                color: $color-black-500;
                font-weight: 500;
                border-bottom: .1rem solid $color-black-500;
            }
        }
    }

    &__best-bets {
        strong {
            font-size: $font-size-headline-4-desktop;
            line-height: $line-height-headline-4;
            font-weight: 500;
            display: block;
        }
        small {
            @include font-paragraph-book-5;
            color: rgba($color-black-500, .5);
            display: block;
        }

        ul {
            list-style: none;
        }
        li {
            margin-top: 1.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        nav {
            margin-top: 3rem;

            + nav {
                margin-top: 2rem;
                padding-top: 2rem;
                border-top: .1rem solid $offcanvas-divider-border;
            }
        }
    }

    &__pagination {
        margin: $offcanvas-padding 0;
        height: 4.4rem;
        display: flex;
        justify-content: space-between;

        button {
            @include transition(background-color);
            position: relative;
            width: 4.4rem;
            height: 4.4rem;
            background: $color-white;
            cursor: pointer;
            display: flex;
            justify-content: center;

            &[disabled] {
                pointer-events: none;

                &::after {
                    opacity: .2;
                }
            }

            @media (hover: hover) {
                &:hover {
                    color: $color-white;
                    background-color: $color-red-500;
                }
            }

            &::after {
                @include transition(border-color);
                display: block;
                content: '';
                border: .2rem solid;
                width: 1rem;
                height: 1rem;
                position: absolute;
                top: calc(50% - 0.5rem);
                border-left: none;
                border-top: none;
                transform: rotate(-45deg);
            }

            &:first-child {
                left: 0;

                &::after {
                    margin-left: .3rem;
                    transform: rotate(135deg);
                }
            }
            &:last-child {
                right: 0;

                &::after {
                    margin-right: .3rem;
                }
            }
        }
    }

    &__catalog-button {
        width: 100%;
        margin: 2.5rem 0;
        display: none;
    }

    &__no-results {
        flex-direction: column;
        align-items: center;
        text-align: center;
        display: none;

        strong {
            display: block;
            font-weight: 500;
            margin: 2.5rem 0;
        }
        button + button {
            margin-top: 1rem;
        }
    }
}
