.simple-teaser {

    &__item {
        width: 100%;
        background-color: $color-white;

        img {
            width: 100%;
        }

        a {
            text-decoration: none;
            color: $color-black-500;
        }

        @include breakpoint-property(
                'padding',
                (
                    m: 0 1.5rem,
                    l: 0 2.5rem,
                    xl: 0 3.75rem
                )
        );

        @include breakpoint-property(
                'margin-bottom',
                (
                    s: 4rem,
                    l: 5rem,
                )
        );

        @include breakpoint-only(s) {
            margin-right: 10rem;
        }

        @include breakpoint(m) {
            width: calc(100% / 3);
        }
    }

    &__text {
        position: relative;
        margin: -2rem 0 0 1.5rem;
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 450;

        h3,
        p {
            @include transition(color);
            &:hover {
                color: $color-red-500;
            }
        }

        h3 {
            display: inline-block;
            font-size: 2.4rem;
            line-height: 3.21rem;
            font-weight: 500;
            padding: .9rem 1.5rem 0.5rem 1.5rem;
            background-color: $color-white;
            @include transition(color);

            &:hover {
                text-decoration: underline;
                text-underline-offset: .5rem;
            }
        }

        p {
            padding: 0 1.5rem .9rem 1.5rem;
        }

        @include breakpoint-only(s) {
            margin-right: -5.5rem;
        }

        @include breakpoint(l) {
            margin: -4rem 0 0 1.5rem;
        }
    }
}
