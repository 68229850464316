@import 'lib/defaults';
@import 'lib/helpers';

@import 'lib/base';

@import 'lib/features/fluid';
@import 'lib/features/container';
@import 'lib/features/offset';
@import 'lib/features/hide';
@import 'lib/features/distribution';
@import 'lib/features/reverse';
@import 'lib/features/reorder';
@import 'lib/features/alignment';
@import 'lib/features/align-column';
@import 'lib/features/block';
@import 'lib/features/gapless';
@import 'lib/features/column-fractions';

@mixin srgrid-create-grid(
    // Metrics
    $grid-column-count: $srgrid-column-count,
    $grid-gutter-widths: $srgrid-gutter-widths,


    // Base
    $grid-parent-class: $srgrid-parent-class,
    $grid-row-class: $grid-parent-class + $srgrid-bem-element-delimiter + $srgrid-row-element-class,
    $grid-column-class: $grid-parent-class + $srgrid-bem-element-delimiter + $srgrid-column-element-class,


    // Debug Feature
    $grid-debug-feature: false,
    $grid-debug-class: 'srgrid-debug',
    $grid-debug-background-color: $srgrid-debug-background-color,
    $grid-debug-color: $srgrid-debug-color,

    // Fluid Feature
    $grid-fluid-feature: false,
    $grid-fluid-responsive-feature: false,
    $grid-fluid-only-feature: false,

    // Container Feature
    $grid-container-feature: false,
    $grid-container-max-width: $srgrid-container-max-width,
    $grid-container-max-widths: $srgrid-container-max-widths,
    $grid-container-class: $srgrid-parent-class + $srgrid-bem-element-delimiter + $srgrid-container-element-class,

     // Offset Feature
    $grid-offset-feature: false,
    $grid-offset-responsive-feature: false,
    $grid-offset-only-feature: false,
    $grid-offset-class: $grid-column-class + $srgrid-bem-modifier-delimiter + $srgrid-offset-modifier-class,

    // Hide Feature
    $grid-hide-feature: false,
    $grid-hide-responsive-feature: false,
    $grid-hide-only-feature: false,
    $grid-hide-class: $grid-column-class + $srgrid-bem-modifier-delimiter + $srgrid-hide-modifier-class,

    // Distribution Feature
    $grid-distribution-feature: false,
    $grid-distribution-responsive-feature: false,
    $grid-distribution-only-feature: false,
    $grid-distribution-around-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-distribution-around-modifier-class,
    $grid-distribution-between-class: $grid-row-class + $srgrid-bem-modifier-delimiter +  $srgrid-distribution-between-modifier-class,

    // Reverse Feature
    $grid-reverse-feature: false,
    $grid-reverse-responsive-feature: false,
    $grid-reverse-only-feature: false,
    $grid-reverse-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-reverse-modifier-class,

    // Reorder Feature
    $grid-reorder-feature: false,
    $grid-reorder-responsive-feature: false,
    $grid-reorder-only-feature: false,
    $grid-reorder-class: $grid-column-class + $srgrid-bem-modifier-delimiter + $srgrid-reorder-modifier-class,

    // Alignment Feature
    $grid-alignment-feature: false,
    $grid-alignment-responsive-feature: false,
    $grid-alignment-only-feature: false,
    $grid-alignment-start-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-start-modifier-class,
    $grid-alignment-center-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-center-modifier-class,
    $grid-alignment-end-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-end-modifier-class,
    $grid-alignment-top-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-top-modifier-class,
    $grid-alignment-middle-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-middle-modifier-class,
    $grid-alignment-bottom-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-bottom-modifier-class,
    $grid-alignment-stretch-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-stretch-modifier-class,

    // Align Column Feature
    $grid-align-column-feature: false,
    $grid-align-column-responsive-feature: false,
    $grid-align-column-only-feature: false,
    $grid-align-column-top-class: $grid-column-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-top-modifier-class,
    $grid-align-column-middle-class: $grid-column-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-middle-modifier-class,
    $grid-align-column-bottom-class: $grid-column-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-bottom-modifier-class,
    $grid-align-column-stretch-class: $grid-column-class + $srgrid-bem-modifier-delimiter + $srgrid-alignment-stretch-modifier-class,

    // Block Feature
    $grid-block-feature: false,
    $grid-block-responsive-feature: false,
    $grid-block-only-feature: false,
    $grid-block-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-block-modifier-class,

    // Gapless Feature
    $grid-gapless-feature: false,
    $grid-gapless-responsive-feature: false,
    $grid-gapless-only-feature: false,
    $grid-gapless-class: $grid-row-class + $srgrid-bem-modifier-delimiter + $srgrid-gapless-modifier-class,

    // Column Fractions Feature
    $grid-column-fractions-feature: false,
    $grid-column-fractions-responsive-feature: false,
    $grid-column-fractions-only-feature: false,
    $grid-column-fractions: $srgrid-column-fractions
) {

    /* Base */

    @include srgrid-generate-grid-base(
            $grid-parent-class,
            $grid-row-class,
            $grid-column-class,
            $grid-gutter-widths
    );


    /* Features */

    // Debug
    @if $grid-debug-feature {
        @if $grid-debug-class != '' {
            @include srgrid-generate-debug-grid-feature(
                    $grid-debug-class,
                    $grid-debug-background-color,
                    $grid-debug-color,
                    $grid-parent-class,
                    $grid-row-class,
                    $grid-column-class,
                    $grid-gutter-widths
            );
        } @else {
            @error 'For the debug feature you need a debug class ($grid-debug-class)';
        }
    }

    // Fluid Regular
    @if $grid-fluid-feature {
        @include srgrid-generate-fluid-feature(
                $grid-column-class,
                $grid-column-count
        );
    }

    // Fluid Responsive
    @if $grid-fluid-responsive-feature {
        @include srgrid-generate-fluid-responsive-feature(
                $grid-column-class,
                $grid-column-count
        );
    }

    // Fluid Only
    @if $grid-fluid-only-feature {
        @include srgrid-generate-fluid-only-feature(
                $grid-column-class,
                $grid-column-count
        );
    }

    // Container
    @if $grid-container-feature {
        @include srgrid-generate-container-feature(
                $grid-container-class,
                $grid-container-max-widths
        );
    }

    // Offset Regular
    @if $grid-offset-feature {
        @include srgrid-generate-offset-feature(
                $grid-offset-class,
                $grid-column-count
        );
    }

    // Offset Responsive
    @if $grid-offset-responsive-feature {
        @include srgrid-generate-offset-responsive-feature(
                $grid-offset-class,
                $grid-column-count
        );
    }

    // Offset Only
    @if $grid-offset-only-feature {
        @include srgrid-generate-offset-only-feature(
                $grid-offset-class,
                $grid-column-count
        );
    }

    // Hide Regular
    @if $grid-hide-feature {
        @include srgrid-generate-hide-feature(
                $grid-hide-class
        );
    }

    // Hide Responsive
    @if $grid-hide-responsive-feature {
        @include srgrid-generate-hide-responsive-feature(
                $grid-hide-class
        );
    }

    // Hide Only
    @if $grid-hide-only-feature {
        @include srgrid-generate-hide-only-feature(
                $grid-hide-class
        );
    }

    // Distribution Regular
    @if $grid-distribution-feature {
        @include srgrid-generate-distribution-feature(
                $grid-distribution-around-class,
                $grid-distribution-between-class
        );
    }

    // Distribution Responsive
    @if $grid-distribution-responsive-feature {
        @include srgrid-generate-distribution-responsive-feature(
                $grid-distribution-around-class,
                $grid-distribution-between-class
        );
    }

    // Distribution Only
    @if $grid-distribution-only-feature {
        @include srgrid-generate-distribution-only-feature(
                $grid-distribution-around-class,
                $grid-distribution-between-class
        );
    }

    // Reverse Regular
    @if $grid-reverse-feature {
        @include srgrid-generate-reverse-feature(
                $grid-reverse-class
        );
    }

    // Reverse Responsive
    @if $grid-reverse-responsive-feature {
        @include srgrid-generate-reverse-responsive-feature (
                $grid-reverse-class
        );
    }

    // Reverse Only
    @if $grid-reverse-only-feature {
        @include srgrid-generate-reverse-only-feature (
                $grid-reverse-class
        );
    }

    // Reorder Regular
    @if $grid-reorder-feature {
        @include srgrid-generate-reorder-feature(
                $grid-reorder-class,
                $grid-column-count
        );
    }

    // Reorder Responsive
    @if $grid-reorder-responsive-feature {
        @include srgrid-generate-reorder-responsive-feature(
                $grid-reorder-class,
                $grid-column-count
        );
    }

    // Reorder Only
    @if $grid-reorder-only-feature {
        @include srgrid-generate-reorder-only-feature(
                $grid-reorder-class,
                $grid-column-count
        );
    }

    // Alignment Regular
    @if $grid-alignment-feature {
        @include srgrid-generate-alignment-feature(
                $grid-alignment-start-class,
                $grid-alignment-center-class,
                $grid-alignment-end-class,
                $grid-alignment-top-class,
                $grid-alignment-middle-class,
                $grid-alignment-bottom-class,
                $grid-alignment-stretch-class
        );
    }

    // Alignment Responsive
    @if $grid-alignment-feature {
        @include srgrid-generate-alignment-responsive-feature(
                $grid-alignment-start-class,
                $grid-alignment-center-class,
                $grid-alignment-end-class,
                $grid-alignment-top-class,
                $grid-alignment-middle-class,
                $grid-alignment-bottom-class,
                $grid-alignment-stretch-class
        );
    }

    // Alignment Only
    @if $grid-alignment-feature {
        @include srgrid-generate-alignment-only-feature(
                $grid-alignment-start-class,
                $grid-alignment-center-class,
                $grid-alignment-end-class,
                $grid-alignment-top-class,
                $grid-alignment-middle-class,
                $grid-alignment-bottom-class,
                $grid-alignment-stretch-class
        );
    }

    // Align Column Regular
    @if $grid-align-column-feature {
        @include srgrid-generate-align-column-feature(
                $grid-align-column-top-class,
                $grid-align-column-middle-class,
                $grid-align-column-bottom-class,
                $grid-align-column-stretch-class
        );
    }

    // Align Column Responsive
    @if $grid-align-column-responsive-feature {
        @include srgrid-generate-align-column-responsive-feature(
                $grid-align-column-top-class,
                $grid-align-column-middle-class,
                $grid-align-column-bottom-class,
                $grid-align-column-stretch-class
        );
    }

    // Align Column Only
    @if $grid-align-column-only-feature {
        @include srgrid-generate-align-only-column-feature(
                $grid-align-column-top-class,
                $grid-align-column-middle-class,
                $grid-align-column-bottom-class,
                $grid-align-column-stretch-class
        );
    }

    // Block Regular
    @if $grid-block-feature {
        @include srgrid-generate-block-feature(
            $grid-block-class,
            $grid-column-class,
            $grid-column-count
        );
    }

    // Block Responsive
    @if $grid-block-responsive-feature {
        @include srgrid-generate-block-responsive-feature(
                $grid-block-class,
                $grid-column-class,
                $grid-column-count
        );
    }

    // Block Only
    @if $grid-block-only-feature {
        @include srgrid-generate-block-only-feature(
                $grid-block-class,
                $grid-column-class,
                $grid-column-count
        );
    }

    // Gapless Regular
    @if $grid-gapless-feature {
        @include srgrid-generate-gapless-feature(
            $grid-gapless-class,
            $grid-column-class
        );
    }

    // Gapless Responsive
    @if $grid-gapless-responsive-feature {
        @include srgrid-generate-gapless-responsive-feature(
                $grid-gapless-class,
                $grid-column-class
        );
    }

    // Gapless Only
    @if $grid-gapless-only-feature {
        @include srgrid-generate-gapless-only-feature(
                $grid-gapless-class,
                $grid-column-class
        );
    }

    // Column Fractions Regular
    @if $grid-column-fractions-feature {
        @include srgrid-generate-column-fractions-feature(
                $grid-column-class,
                $grid-column-fractions,
                $grid-gutter-widths
        );
    }

    // Column Fractions Responsive
    @if $grid-column-fractions-responsive-feature {
        @include srgrid-generate-column-fractions-responsive-feature(
                $grid-column-class,
                $grid-column-fractions,
                $grid-gutter-widths
        );
    }

    // Column Fractions Only
    @if $grid-column-fractions-only-feature {
        @include srgrid-generate-column-fractions-only-feature(
                $grid-column-class,
                $grid-column-fractions,
                $grid-gutter-widths
        );
    }
}
