.product-big-tiles {
    margin-top: -5rem;
}

.product-big-tile {
    display: flex;
    margin-top: 5rem;

    @include breakpoint(l) {
        display: block;
    }
    &__image-wrapper {
        @include breakpoint(m) {
            margin-top: 2.4rem;
            width: 37.5%;
            text-align: center;
        }
        @include breakpoint(l) {
            width: 100%;
            margin-top: 0;
            margin-bottom: 2rem;
            text-align: left;
        }
    }
    &__image {
        filter: $drop-shadow-filter-small;
        width: 80px;
        height: auto;
        margin-right: 3rem;

        @include breakpoint(m) {
            max-width: 161px;
            width: 100%;
        }
        @include breakpoint(l) {
            max-width: 72%;
        }
    }
    &__content {
        @include breakpoint(m) {
            width: 50%;
        }
        @include breakpoint(l) {
            width: 100%;
        }
    }
    &__badges {
        margin-bottom: 1rem;
    }
    &__title {
        @include font-headline-6;
        color: $color-black-500;
        margin-bottom: 1.5rem;
        @include line-clamp(3);
        padding-bottom: 2px;
        .link {
            text-underline-offset: 0.3rem;
        }
    }
    &__copy {
        display: table;
        @include breakpoint(l) {
            display: block;
        }
        p {
            @include font-paragraph-book-4;
            display: table-row;
            @include breakpoint(l) {
                display: block;
                margin-bottom: 0.5rem;
            }
            
            strong {
                display: table-cell;
                padding: 0 1rem 0.5rem 0;
                @include breakpoint(l) {
                    display: inline;
                    padding: 0 0.5rem 0 0;
                }
            }
        }
    }
    &__ean {
        margin: 0;
        display: flex;
        &__label {
            @include font-paragraph-bold-4;
            margin-right: 1.5rem
        }
        &__value {
            @include font-paragraph-book-4;
        }
    }
}
