$font-family: 'Circular', Arial;

$font-weight-regular: 450;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$color-white: #fff;
$color-black-500: #171717;
$color-black-400: #515151;
$color-black-300: #8B8B8B;
$color-black-200: #C5C5C5;
$color-black-100: #F3F3F3;

$color-red-500: #DF2900;
$color-red-400: #E75F40;
$color-red-300: #EF9480;
$color-red-200: #F7C9BF;

$color-cool-grey-500: #53565A;
$color-cool-grey-400: #7E8083;
$color-cool-grey-300: #A9AAAC;
$color-cool-grey-100: #F6F7F7;

$color-success-500: #4BB84B;
$color-success-400: #78CA78;
$color-success-300: #A5DBA5;
$color-success-200: #D2EDD2;

$color-error-500: #C72619;
$color-error-400: #D55C53;
$color-error-300: #E3928C;
$color-error-200: #F1C9C6;

$color-kristall-500: #6DD0DF;
$color-kristall-400: #91DBE7;
$color-kristall-300: #B6E7EF;
$color-kristall-200: #DAF3F7;

$color-jade-500: #B6D82E;
$color-jade-400: #C8E262;
$color-jade-300: #DBEB96;
$color-jade-200: #EDF5CB;

$color-gold-500: #A99E83;
$color-gold-400: #CFC8B9;
$color-gold-300: #E9E7E0;
$color-gold-200: #F9F9F7;

$color-warning-500: #F2994A;
$color-warning-400: #F7C699;
$color-warning-300: #FCE5D1;
$color-warning-200: #FEF9F4;

$font-size-display-1-desktop: 5.6rem;
$font-size-display-2-desktop: 4.8rem;
$font-size-display-1-tablet: 4.8rem;
$font-size-display-2-tablet: 4rem;
$font-size-display-1-mobile: 3.6rem;
$font-size-display-2-mobile: 3rem;

$font-size-headline-1-desktop: 4rem;
$font-size-headline-1-tablet: 3.4rem;
$font-size-headline-1-mobile: 3rem;
$font-size-headline-2-desktop: 3.4rem;
$font-size-headline-2-tablet: 2.8rem;
$font-size-headline-2-mobile: 2.4rem;
$font-size-headline-3-desktop: 2.8rem;
$font-size-headline-3-tablet: 2.4rem;
$font-size-headline-3-mobile: 2.2rem;
$font-size-headline-4-desktop: 2.2rem;
$font-size-headline-4: 2rem;
$font-size-headline-5: 1.8rem;
$font-size-headline-6: 1.6rem;

$font-size-paragraph-1: 2rem;
$font-size-paragraph-2: 1.8rem;
$font-size-paragraph-3: 1.6rem;
$font-size-paragraph-4: 1.4rem;
$font-size-paragraph-5: 1.2rem;

$line-height-display: 110%;
$line-height-display-2-mobile: 120%;

$line-height-headline-1-desktop: 110%;
$line-height-headline-1-tablet: 115%;
$line-height-headline-1-mobile: 120%;
$line-height-headline-2-desktop: 130%;
$line-height-headline-2: 140%;
$line-height-headline-3: 130%;
$line-height-headline-4: 130%;
$line-height-headline-5: 130%;
$line-height-headline-6: 130%;

$line-height-paragraph-1: 150%;
$line-height-paragraph-2: 155%;
$line-height-paragraph-3: 155%;
$line-height-paragraph-4: 150%;
$line-height-paragraph-5: 150%;

$letter-spacing-xs: -2px;
$letter-spacing-s: -0.5px;
$letter-spacing-m: -0.1px;
$letter-spacing-l: 0.16px;

/* Header */
$header-height-mobile: 9.4rem;
$header-height-tablet: 11.5rem;
$header-height-desktop: 14rem;
$header-z-index: 99;

/* Footer */
$footer-background-color: $color-white;
$footer-divider-border: rgba($color-cool-grey-500, 0.25);
$footer-divider-border-active: $color-black-500;

/* Off-Canvas */
$offcanvas-width-mobile: 34.4rem;
$offcanvas-width-desktop: 42rem;
$offcanvas-overlay-background-color: rgba(83, 86, 90, 0.8);
$offcanvas-divider-border: rgba($color-cool-grey-500, 0.25);
$offcanvas-z-index: 100;
$offcanvas-padding: 2.4rem;
$offcanvas-header-height: 8rem;

/* Accordion */
$accordion-divider-border: rgba($color-cool-grey-500, 0.25);
$accordion-divider-border-active: $color-black-500;

/* News Listing */
$news-divider-border: rgba($color-cool-grey-500, 0.05);

/* Forms */
$form-input-border: rgba($color-cool-grey-500, 0.25);
$form-textarea-border: rgba($color-cool-grey-300, 0.5);
$form-max-width-desktop-xl: 57rem;
$form-max-width-desktop: 43rem;
$form-max-width-tablet: 49.5rem;

/* Newsletter Module */
$newsletter-module-bg: rgba($color-cool-grey-500, 0.05);
$newsletter-label-font-size: 1rem;
$newsletter-input-border: rgba($color-cool-grey-500, 0.25);

/* Notification */
$notification-error-text-color: #801700;
$notification-success-text-color: #215421;
$notification-warning-text-color: #804109;
$notification-warning-icon-color: #EE7911;
$notification-info-text-color: #18616D;

/* Slider */
$slider-background-color: $color-black-100;

/* App Teaser */
$app-background-color: rgba($color-cool-grey-500, 0.05);

/* Shadows */
$teaser-box-shadow: 1.5rem 1.5rem 3rem 0 #0000000D;
$drop-shadow-filter-big: drop-shadow(2.1rem 2.1rem 3.5rem rgba($color-black-500, 0.2));
$drop-shadow-filter-medium: drop-shadow(1.5rem 1.5rem 2.5rem rgba($color-black-500, 0.2));
$drop-shadow-filter-small: drop-shadow(1.2rem 1.2rem 1.5rem rgba($color-black-500, 0.2));

/* Category Product */
$category-product-background-color: rgba($color-cool-grey-500, 0.05);

/* Story Quote */
$quote-alternative-bg: rgba($color-cool-grey-500, 0.05);