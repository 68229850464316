.image-video-text {
    
    @include breakpoint(m) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }
    &__image-figure {
        position: relative;
        margin-bottom: 0;
    }
    &__image {
        display: block;
        height: auto;
        @include breakpoint-property(
            'width',
            (
                s: 100vw,
                m: calc(100% + 35px),
                l: calc(100% + 35px),
                xl: 100%
            )
        );
        @include breakpoint-property(
            'max-width',
            (
                s: 100vw,
                m: calc(100% + 35px),
                l: calc(100% + 35px),
                xl: 100%
            )
        );
    }
    .slider {
        @include breakpoint-property(
            'width',
            (
                s: 100vw,
                m: 100%
            )
        );
    }
    &__figcaption {
        margin-top: 1rem;
        color: $color-black-400;
        @include font-paragraph-book-5;
        @include breakpoint-property(
            'padding',
            (
                s: 0 $container-padding-s,
                m: 0
            )
        );
    }

    &__content {
        @include breakpoint-property(
            'margin-top',
            (
                s: 2.5rem,
                m: 0
            )
        );
    }
    &__headline {
        color: $color-black-500;
        @include breakpoint-property(
            'margin-bottom',
            (
                s: 2rem,
                l: 2.5rem
            )
        );
    }
    &__button {
        margin-top: .5rem;
        display: inline-block;
    }

    /* VARIANT Image/video on the right (default) */
    &.image-video-text--has-image-right {
        @include breakpoint-property(
            'flex-direction',
            (
                s: inherit,
                m: row-reverse
            )
        );
        .image-video-text__image-figure {
            padding: 0;
        }
        @include breakpoint-property(
            'flex-direction',
            (
                s: inherit,
                m: row-reverse
            )
        );
        .image-video-text__image-figure {
            @include breakpoint-property(
                'right',
                (
                    s: 9px,
                    m: -1px,
                    l: -15px,
                    xl: 0
                )
            );
        }
    }

    /* VARIANT Image/video on the left */
    &.image-video-text--has-image-left {
        .image-video-text__image-figure {
            padding: 0;

            @include breakpoint-property(
                'left',
                (
                    s: -9px,
                    m: -36px,
                    l: -50px,
                    xl: 0
                )
            );

        }
        .image-video-text__figcaption {
            @include breakpoint-property(
                'margin-left',
                (
                    s: 0,
                    m: 1.5rem,
                    xl: 0
                )
            );
        }
    }

    /* VARIANT Image/video in 1 col */
    &.image-video-text--image-full-width {
        @include breakpoint(m) {
            display: block;
        }
        .image-video-text__image-figure {
            position: static;
            width: 100%;
            @include breakpoint-property(
                'margin-left',
                (
                    s: 0,
                    xl: 0
                )
            );
        }
    }

    /* VARIANT 2 Images/videos in 2 cols */
    &.image-video-text--image-2cols {
        display: flex;
        align-items: flex-start;
    }

    &.image-video-text--video-aspect-ratio {
        picture {
            position: relative;
            width: 100%;
            padding-top: 56.25%; /* 16:9 aspect ratio (9/16 = 0.5625) */
            overflow: hidden;
            display: block;
        }
       
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        
        .image-video-text__image-figure + .image-video-text__image-figure {
            @include breakpoint-property(
                'margin-top',
                (
                    s: 2rem,
                    m: 0
                )
            );
        }

        .video__container.image-video-text__image {
            width: 100%;
        }
    }
}
