.offcanvas {
    $offcanvas-root: &;

    background-color: $offcanvas-overlay-background-color;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $offcanvas-z-index;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    will-change: opacity, visibility;
    @include transition(opacity, visibility);

    button {
        display: block;
        background: none;
    }

    &[data-is-open="1"] {
        opacity: 1;
        visibility: visible;
    }

    @include breakpoint(m) {
        backdrop-filter: blur(0.5rem);
        -webkit-backdrop-filter: blur(0.5rem);
    }

    &__sidebar {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $color-cool-grey-100;
        width: $offcanvas-width-mobile;
        overflow: hidden;

        @include transition(transform, visibility);
        visibility: hidden;
        transform: translate3d(100%, 0, 0);
        will-change: transform, visibility;

        @include breakpoint(m) {
            width: $offcanvas-width-desktop;
        }

        #{$offcanvas-root}[data-is-open="1"] & {
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2.5rem $offcanvas-padding;
        background-color: $color-white;
        position: fixed;
        width: inherit;
        height: $offcanvas-header-height;

        img,
        svg {
            display: block;
        }
    }

    &__headline {
        font-size: $font-size-headline-4;
        line-height: $line-height-headline-4;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 2rem;
        pointer-events: none;
    }

    &__button {
        @include transition(color);

        &:hover {
            color: $color-red-500;
        }

        &--back {
            border: .2rem solid;
            width: 1rem;
            height: 1rem;
            position: relative;
            left: .6rem;
            border-right: none;
            border-bottom: none;
            transform: rotate(-45deg);
        }

        &--close {
            width: 3rem;
            height: 3rem;

            svg {
                fill: none;
            }
        }
    }

    &__main {
        position: absolute;
        overflow-x: hidden;
        overflow-y: auto;
        bottom: 0;
        top: 8rem;
        width: 100%;

        .offcanvas__navigation--lvl1 {
            &.is-active {
                + .offcanvas__lang {
                    display: none;
                }
            }
        }
    }
}

@import "./offcanvas-login";
@import "./offcanvas-navigation";
@import "./offcanvas-search";
