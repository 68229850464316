.video {
    &__player {
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    &__container {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
    }

    &__caption {
        @include font-paragraph-book-5;
        color: $color-black-500;
        text-align: left;
        padding-top: 1rem;
    }
}

