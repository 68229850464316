.job-teaser-iframe {
    &__title {
        @include font-headline-2;
        margin-bottom: 4.4rem;
    }

    &__labels {
        position: relative;
        @include font-paragraph-book-4;
        background-color: $color-black-100;
        padding: 3.5rem 0;

        &:before {
            content: '';
            background-color: $color-black-100;
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: -1;

            @include breakpoint-property(
                'margin',
                (
                    s: 0 -#{$container-padding-s},
                    m: 0 -#{$container-padding-m},
                    l: 0 -#{$container-padding-l},
                    xl: 0 -#{$container-padding-xl},
                    xxl: 0 -#{$container-padding-xxl}
                )
            );
        }

        p:not(:last-child) {
            margin-bottom: 1.2rem;
        }

        strong {
            padding-right: 0.5rem;
        }
    }

    iframe {
        margin-top: 5.3rem;
        height: 100vh;
        width: 100%;
    }
}
