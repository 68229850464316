.quote {
    $quote-root: &;

    position: relative;

    &--no-image,
    &--alternative-bg.quote--no-image {
        #{$quote-root}__content {
            text-align: center;

            blockquote,
            p {
                margin: 0 auto;
            }

            blockquote {
                @include font-headline-3;
                font-weight: 500;
                @include breakpoint-property(
                    'width',
                    (
                        s: calc(2 * 100% / 2),
                        m: calc(6 * 100% / 8),
                        l: calc(8 * 100% / 12)
                    )
                );
            }

            p {
                @include font-paragraph-book-5;
                padding: 0;
                text-align: center;
                width: 100%;

                @include breakpoint-property(
                    'width',
                    (
                        s: calc(2 * 100% / 2),
                        m: calc(4 * 100% / 12)
                    )
                );

                @include breakpoint-property(
                    'padding-top',
                    (
                        s: 2rem,
                        m: 3rem,
                        l: 4rem
                    )
                );
            }
        }
    }

    &--has-image {
        #{$quote-root}__image {
            width: 100%;
            display: block;
            aspect-ratio: 16/9;
            object-fit: cover;

            @include breakpoint-only(s) {
                width: calc(100% + 4.8rem);
                margin-left: -2.4rem;
                margin-right: -2.4rem;
            }
        }

        #{$quote-root}__content {
            position: relative;

            @include breakpoint-property(
                'margin-left',
                (
                    m: 1.9rem,
                    l: 2.5rem,
                    xl: 3.5rem
                )
            );

            @include breakpoint-property(
                'width',
                (
                    s: 100%,
                    m: 26.1rem,
                    l: 27.8rem,
                    xl: 40rem
                )
            );

            @include breakpoint-only(s) {
                margin-top: -2rem;
            }
        }

        #{$quote-root}__headline,
        #{$quote-root}__subline {
            background-color: $color-white;
        }

        #{$quote-root}__headline {
            position: relative;
            font-weight: 500;
            font-style: italic;

            @include breakpoint(m) {
                position: absolute;
                bottom: 100%;
            }

            blockquote {
                line-height: 130%;
                text-align: left;
                width: 100%;
                font-weight: 500;
                @include breakpoint-property(
                    'font-size',
                    (
                        s: 2rem,
                        m: 2.2rem,
                        l: 2.4rem,
                        xl: 2.6rem
                    )
                );
            }

            @include breakpoint-property(
                'padding',
                (
                    s: 2rem 2.6rem 2rem 2rem,
                    m: 2rem 2rem 0,
                    xl: 3rem 3rem 0
                )
            );
        }

        #{$quote-root}__subline {
            @include font-paragraph-book-5;
            padding: 0;
            text-align: left;
            width: 100%;

            @include breakpoint-property(
                'padding',
                (
                    s: 0 2.6rem 2rem 2rem,
                    m: 1.2rem 2rem 2rem 2rem,
                    xl: 1.2rem 3rem 3rem 3rem
                )
            );
        }
    }

    &--alternative-bg {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 50%;
            margin-left: -50vw;
            width: 100vw;
            background: $quote-alternative-bg;
            z-index: -1;
        }
        
        @include breakpoint-property(
            'padding-top',
            (
                s: 6rem,
                m: 8rem
            )
        );
        @include breakpoint-property(
            'padding-bottom',
            (
                s: 6rem,
                m: 8rem
            )
        );
    }
}
