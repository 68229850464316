hr[data-label] {
    position: relative;
    border-top: .1rem solid $color-cool-grey-500;

    &::after {
        color: $color-white;
        font-size: 1.2rem;
        line-height: 1.2rem;
        white-space: nowrap;
        padding: .2rem .4rem;
        content: attr(data-label);
        background: $color-cool-grey-500;
        position: absolute;
        top: -0.8rem;
        left: 1.6rem;
    }
}

@mixin line-clamp( $lines ) {
    line-clamp: $lines;
    -webkit-line-clamp: $lines;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

@mixin aspect-ratio-fallback($width: 16, $height: 9) {
    
    @supports not (aspect-ratio: $width / $height) {
        &::before {
            content: '';
            float: left;
            padding-top: calc((#{$height} / #{$width}) * 100%);
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }
}
