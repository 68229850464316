.list {
    list-style: none;

    &--ordered {
        list-style: decimal;
    }
    &--unordered {
        list-style: disc;
    }

    &--ordered,
    &--unordered {
        padding: revert;
    }

    &--copy-combination {
        p,
        li {
            margin-top: 2.5rem;
        }
    }

    + .list {
        margin-top: 7rem;
    }
}
