// Parent Element
@mixin srgrid-generate-grid-parent(
    $grid-parent-class
) {
    .#{$grid-parent-class} {
        overflow-x: hidden;
    }
}

// Row
@mixin srgrid-generate-grid-row-base($gutter-width) {
    margin-left: - $gutter-width / 2;
    margin-right: - $gutter-width / 2;
}

@mixin srgrid-generate-grid-row(
    $grid-row-class,
    $grid-gutter-widths
) {
    .#{$grid-row-class} {
        // overflow: hidden;
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;

        @if length($grid-gutter-widths) == 1 {
            @include srgrid-generate-grid-row-base($grid-gutter-widths);
        } @else {
            $breakpoints: map-keys($srgrid-breakpoints);
            $gutter-widths: map-values($grid-gutter-widths);

            @for $index from 1 through length($srgrid-breakpoints) {
                $breakpoint-name: nth($breakpoints, $index);
                $gutter-width: nth($gutter-widths, $index);

                @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
                    @include srgrid-generate-grid-row-base($gutter-width);
                }
            }
        }
    }
}

// Column
@mixin srgrid-generate-grid-column-base($gutter-width) {
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2;
}

@mixin srgrid-generate-grid-column(
    $grid-column-class,
    $grid-gutter-widths
) {
    .#{$grid-column-class} {
        flex: 1 0 auto;

        @if length($grid-gutter-widths) == 1 {
            @include srgrid-generate-grid-column-base($grid-gutter-widths);
        } @else {
            $breakpoints: map-keys($srgrid-breakpoints);
            $gutter-widths: map-values($grid-gutter-widths);

            @for $index from 1 through length($srgrid-breakpoints) {
                $breakpoint-name: nth($breakpoints, $index);
                $gutter-width: nth($gutter-widths, $index);

                @include srgrid-content-breakpoint-only($breakpoint-name, $srgrid-breakpoints) {
                    @include srgrid-generate-grid-column-base($gutter-width);
                }
            }
        }
    }
}


// sR Grid Base
@mixin srgrid-generate-grid-base(
    $grid-parent-class,
    $grid-row-class,
    $grid-column-class,
    $grid-gutter-widths
) {
    /* Base */

    // Parent Element
    // @include srgrid-generate-grid-parent(
    //         $grid-parent-class
    // );

    // Row
    @include srgrid-generate-grid-row(
            $grid-row-class,
            $grid-gutter-widths
    );

    // Column
    @include srgrid-generate-grid-column(
            $grid-column-class,
            $grid-gutter-widths
    );
}
