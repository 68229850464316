.people-item {
    width: 100%;

    @include breakpoint-property(
        'padding',
        (
            m: 0 1.5rem,
            l: 0 2.5rem,
            xl: 0 3.75rem
        )
    );

    @include breakpoint(l) {
        width: (100% / 3);
    }

    @include breakpoint-only(m) {
        width: (100% / 2);
    }

    img {
        display: block;
        aspect-ratio: 1/1;
        object-fit: cover;
        width: 17.5rem;
        height: auto;

        @include breakpoint(m) {
            width: 100%;
        }
    }

    &__content {

        @include breakpoint-property(
            'margin',
            (
                s: 2.5rem 0 5rem,
                m: 2.5rem 0 7rem,
                l: 2.5rem 0 8rem,
                xl: 2.5rem 0 10rem
            )
        );


        h3 {
            @include font-headline-3;

            @include breakpoint-property(
                'font-size',
                (
                    s: 2rem,
                    m: 2.2rem,
                    l: 2.8rem
                )
            );
        }

        span {
            @include font-paragraph-book-2;
            display: block;
            margin: 0.5rem 0 2.5rem;

            @include breakpoint(m) {
                @include font-paragraph-book-3;
            }
        }

        div {
            display: flex;

            &:hover {
                a,
                .icon {
                    @include transition(color);
                    color: $color-red-500;
                    border-color: $color-red-500;
                }
            }

            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }

            a {
                @include font-headline-6;
                text-decoration: none;
                border-bottom: 0.2rem solid $color-black-500;
                color: $color-black-500;
            }

            svg {
                fill: none;
                margin-right: 1.5rem;
                color: $color-black-500;
            }

            .icon {
                width: 24px;
                margin-right: 1.5rem;
            }
        }
    }
}
