.category-product {
    display: flex;

    &__container {

        &--light-grey {
            &:before {
                background-color: $category-product-background-color;
            }

            $spacer-sizes: s, m, l, xl;

            @each $size in $spacer-sizes {
                .spacer-#{$size} & {
                    @extend .spacer-inner__top--#{$size};
                }
            }
        }

        &--white {
            &:before {
                background-color: $color-white;
            }
        }

        &--light-grey,
        &--white {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                display: block;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: -1;

                @include breakpoint-property(
                    'margin',
                    (
                        s: 0 -#{$container-padding-s},
                        m: 0 -#{$container-padding-m},
                        l: 0 -#{$container-padding-l},
                        xl: 0 -#{$container-padding-xl},
                        xxl: 0 -#{$container-padding-xxl}
                    )
                );
            }
        }
    }
    .grid__col_12 & {
        flex-direction: column;

        @include breakpoint-property(
            'padding',
            (
                s: 6rem 0,
                m: 8rem 0,
                l: 10rem 0
            )
        );

        @include breakpoint(m) {
            flex-direction: row;
        }

        @include breakpoint-property(
            'padding',
            (
                s: 6rem 0,
                m: 8rem 0,
                l: 10rem 7.7rem 10rem 10.7rem,
                xl: 10rem 13rem
            )
        );

        &__img-wrapper {
            @include breakpoint(m) {
                align-self: center;
            }
        }

        &__content {
            margin-top: 2.5rem;

            @include breakpoint-property(
                'margin-left',
                (
                    m: 8.1rem,
                    l: 9.8rem,
                    xl: 6rem
                )
            );

            @include breakpoint(m) {
                margin-top: 0;
                align-self: flex-start;
            }
        }

        &__copy {
            @include breakpoint-only(m) {
                padding-bottom: 3rem;
            }
        }
    }

    .grid__col_4_l,
    .grid__col_8_m,
    .grid__col_2_s & {
        flex-direction: column;

        @include breakpoint-only(m) {
            flex-direction: row;
        }

        &__content {
            @include breakpoint-property(
                'margin-top',
                (
                    s: 2.5rem,
                    m: 0,
                    l: 2.5rem
                )
            );

            @include breakpoint-only(m) {
                margin-left: 8.1rem;
            }
        }

        &__headline {
            @include font-headline-4;

            @include breakpoint(s) {
                font-size: 2.2rem;
            }
        }

        &__img-wrapper {
            @include breakpoint-only(m) {
                align-self: center;
            }
        }

        &__img {
            @include breakpoint-property(
                'width',
                (
                    s: 16.5rem,
                    m: 18rem
                )
            );
        }

        &__copy {
            @include breakpoint(l) {
                padding: 1.5rem 2.5rem 3rem 0;
            }
        }
    }

    &__headline {
        font-size: 2.2rem;
        line-height: $line-height-headline-4;
        font-weight: 500;

        @include breakpoint(l) {
            @include font-headline-3;
        }
    }

    &__copy {
        @include font-paragraph-book-3;
        padding: 1.5rem 0 3rem;
    }

    &__button {
        display: flex;

        a {
            text-decoration: none;
        }
    }

    &__img {
        display: block;
        height: auto;
        aspect-ratio: 1/1;
        filter: $drop-shadow-filter-big;

        @include breakpoint-property(
            'width',
            (
                s: 16.5rem,
                m: 18rem,
                xl: 21rem
            )
        );
    }

    &--multi {
        padding-bottom: 6rem;
    }
}
