.image-block {
    $image-block-root: &;

    &--intro {
        @include breakpoint-property(
            'padding-bottom',
            (
                s: 18.5rem,
                m: 38.4rem - 20rem,
                l: 51.2rem - 26.3rem,
                xl: 64rem - 29rem
            )
        );

        .img {
            left: 0;
            right: 0;
            position: absolute;
            display: block;
            aspect-ratio: 2/1;
            object-fit: cover;
            width: 100%;
            @include breakpoint-property(
                'height',
                (
                    s: 18.5rem,
                    m: 18.5rem,
                    l: 25rem,
                    xl: 35rem
                )
            );
        }

        #{$image-block-root}__figcaption {
            @include breakpoint-property(
                'top',
                (
                    s: 18.5rem,
                    m: 38.4rem - 20rem,
                    l: 51.2rem - 26.3rem,
                    xl: 64rem - 29rem
                )
            );
        }
    }

    &--content {
        .img {
            width: 100%;
        }
    }

    &__figcaption {
        position: relative;
        @include font-paragraph-book-5;
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 1.5rem 2.5rem;
        color: $color-black-400;
    }
}
